import React, { Suspense, useState, useEffect } from "react";
import clientProfileServices from "../clientProfileServices";
import SearchTerritory from "./search_Territory";
import { format, getDate } from "date-fns";
import DatePickerRange from "../../../PeriodSelection/DatePickerRange";

export const Details_Template = () => {

    let [detailslist,setDetailslist]                    = useState<any>([]);
    let [territoryList,setTerritoryList]                = useState<any>([]);
    let [ContractStatus,setContractStatus ]             = useState<any>([]);
    let [Reportings, setReportings]                     = useState<any>([]);
    let [Basis,setBasic]                                = useState<any>([]);
    let [contractParties,setcontractParties]            = useState<any>([]);
    let [StatusAccount,setStatusAccount]                = useState<any>([]);
    let [StatusAccountExt,setStatusAccountExt]          = useState<any>([]);
    let [StatusStatement,setStatusStatement]            = useState<any>([]);
    let [reportingName,setReportingName]                = useState<any>([]);
    let [ClientProfileTypes,setClientProfileTypes]      = useState("Contact")
    let [openTerritoryDetail,setopenTerritoryDetail]    = useState(false)
    let invoiceTypes = [
        { invoiceType: "Both"       ,invoiceTypeIndex: "both" },
        { invoiceType: "Publishing" ,invoiceTypeIndex: "publisher"},
        { invoiceType: "Recording"  ,invoiceTypeIndex: "recording" }
    ];

    useEffect(() => {
        clientProfileServices.clientDetails() .then((res) => {
            //for normal fields
            setDetailslist(res[0]);
            ContractStatus = global.lookUpTypeList.filter((lookUpListItem: any) => lookUpListItem.lookUpType == 'ContractStatus')
            setContractStatus(ContractStatus)
            Reportings = global.lookUpTypeList.filter((lookUpListItem: any) => lookUpListItem.lookUpType == 'Reportings')
            setReportings(Reportings)
            Basis = global.lookUpTypeList.filter((lookUpListItem: any) => lookUpListItem.lookUpType == 'Basis')
            setBasic(Basis)
            contractParties = global.lookUpTypeList.filter((lookUpListItem: any) => lookUpListItem.lookUpType == 'contractParties')
            setcontractParties(contractParties)

            StatusAccount = global.lookUpTypeList.filter((lookUpListItem: any) => lookUpListItem.lookUpType == 'StatusAccount')
            setStatusAccount(StatusAccount)
            StatusAccountExt = global.lookUpTypeList.filter((lookUpListItem: any) => lookUpListItem.lookUpType == 'StatusAccountExt')
            setStatusAccountExt(StatusAccountExt)
            StatusStatement = global.lookUpTypeList.filter((lookUpListItem: any) => lookUpListItem.lookUpType == 'StatusStatement')
            setStatusStatement(StatusStatement)
        })
        
        clientProfileServices.getReports().then((res) => { 
            //console.log(res)
            setReportingName(res) 
        })
        //console.log(reportingName)
    }, []);

    const handleFormSubmit =()=>{
        console.log(detailslist)
        clientProfileServices.saveClientDetails(detailslist)
    }

    const handleInputChangeField =(e:any )=>{
        
        if(e.target.type == "checkbox" && ( e.target.value == 0 || e.target.value == 1) ){
            setDetailslist( (previous: any) => ({ ...previous , [e.target.name] : e.target.value==0 ?  1: 0 }) )

        } 
        if(e.target.type == "checkbox" &&   ( Boolean(e.target.value) == true || Boolean(e.target.value) == false) ){
            setDetailslist( (previous: any) => ({ ...previous , [e.target.name] : Boolean(e.target.value) == true?  false : true }) )
        }

        if(e.target.type != "checkbox"){
            setDetailslist( (previous: any) => ({ ...previous , [e.target.name] : e.target.value}) )
        }  

    }

    const dateSelectionStartDate =(e :any) =>{
        //console.log(e.toISOString())  
        setDetailslist( (previous: any) => ({ ...previous , contractStartDate : e.toISOString()}) )
    }

    const dateSelectionEndate =(e :any) =>{
        //console.log(e)
        setDetailslist( (previous: any) => ({ ...previous , contractEndDate : e.toISOString()}) )
    }

    const dateSelectionSignature =(e :any) =>{
        //console.log(e)
        setDetailslist( (previous: any) => ({ ...previous , contractDate : e.toISOString()}) )
    }

    const dateSelectionRED = (e:any)=>{
        //console.log( format(new Date(e), 'yyyyMMdd'))  
        setDetailslist( (previous: any) => ({ ...previous , reportingEndDate : format(new Date(e), 'yyyyMMdd')}) )
    }

    const dateSelectionRSD = (e:any)=>{
        //console.log( format(new Date(e), 'yyyyMMdd'))  
        setDetailslist( (previous: any) => ({ ...previous , reportingStartDate : format(new Date(e), 'yyyyMMdd')}) )
    };     

    const handleTerritorySelection = (territoryName: string, territoryCode: string, territoryCurrency: string) => {
        setDetailslist((prevDetails: any) => ({
            ...prevDetails,
            homeTerritory: territoryCode,
            homeCurrency: territoryCurrency,
        }));
    };

    const closeTerritoryDetail =(e:any)=>{
        setopenTerritoryDetail(false)
    }

    return (

        <div className="row-fluid sortable box box-header-fix form-box allowVscroll" >

            <div className="box-header well"> <h2><i className="icon-edit pt pr"></i>Details</h2>  </div>

            <div className="box-content">
                <div className="form-horizontal form-condensed">

                    <div className="ws-12 box mtl">
                        <div className="box-header"> 
                            <h1>
                                <span className="pull-left cursor-hand"> Contact</span>
                                <button className="btn-success hover-box btn-xs badge pl pr pull-right mr" onClick={()=>setClientProfileTypes('Contact')}>
                                    <i className={ClientProfileTypes == 'Contact' ? "icon-minus1" : "icon-plus1" } ></i>
                                </button>
                            </h1> 
                         </div>

                        {ClientProfileTypes == 'Contact' && <div className="box-content">
                            <div className="form-format ws-3">
                                <label className="control-label ws-3" >Address 1</label>
                                <div className="label-entries">
                                    <input type="text" className="ws-12" name="clientAddress1" value={detailslist.clientAddress1} onChange={handleInputChangeField} placeholder="Enter Address 1" />

                                </div>
                            </div>

                            <div className="form-format ws-3">
                                <label className="control-label 12">Address 2</label>
                                <div className="label-entries">
                                    <input type="text" value={detailslist.clientAddress2} name="clientAddress2" onChange={handleInputChangeField} className="form-control ws-12" placeholder="Enter Address 2" />
                                </div>
                            </div>

                            <div className="form-format ws-3">
                                <label className="control-label ws-3" >Address 3</label>
                                <div className="label-entries">
                                    <input type="text" name="clientAddress3" value={detailslist.clientAddress3} onChange={handleInputChangeField} className="ws-12" placeholder="Enter Address 3"></input>
                                </div>
                            </div>

                            <div className="form-format ws-3">
                                <label className="control-label 12">Address 4</label>
                                <div className="label-entries">
                                    <input type="text" value={detailslist.clientAddress4} name="clientAddress4" onChange={handleInputChangeField} className="form-control ws-12" placeholder="Enter Address 4" />
                                </div>
                            </div>

                            <div className="form-format ws-3">
                                <label className="control-label 12"> Number </label>
                                <div className="label-entries">
                                    <input type="text" value={detailslist.clientNumber} name="clientNumber" onChange={handleInputChangeField} className="form-control ws-12" placeholder="Enter Number" />
                                </div>
                            </div>

                            <div className="form-format ws-3">
                                <label className="control-label 12"> Post Code </label>
                                <div className="label-entries">
                                    <input type="text" value={detailslist.clientPostCode} name="clientPostCode" onChange={handleInputChangeField} className="form-control ws-12" placeholder="Enter Post Code" />
                                </div>
                            </div>

                            <div className="form-format ws-3">
                                <label className="control-label 12"> Contact </label>
                                <div className="label-entries">
                                    <input type="text" className="form-control ws-12" name="clientContact" onChange={handleInputChangeField} value={detailslist.clientContact} placeholder="Enter Contact" />
                                </div>
                            </div>

                        </div>}

                    </div>

                    <div className="ws-12 box mtl">
                        <div className="box-header">
                            <h1>
                                <span className="pull-left cursor-hand"> Contract</span>
                                <button className="btn-success hover-box btn-xs badge pl pr pull-right mr" onClick={()=>setClientProfileTypes('Contract')}>
                                    <i className={ClientProfileTypes == 'Contract' ? "icon-minus1" : "icon-plus1" } ></i>
                                </button>
                            </h1> 
                        </div>

                        {ClientProfileTypes == 'Contract' && <div className="box-content">
                            
                           <div className="form-format ws-3">
                                <label className="control-label">Contract Party</label>
                                <div className="label-entries">
                                    <select  onChange={handleInputChangeField} name="contractParty" value={detailslist.contractParty }>
                                        <option value="">Choose contract Party</option>
                                        <option value="0" label="Artist">Artist</option>
                                        <option value="1" label="3rd Party">3rd Party</option>
                                        <option value="2" label="Licensor">Licensor</option>
                                        <option value="3" label="Licensee">Licensee</option>
                                        <option value="4" label="Publisher">Publisher</option>
                                    </select>
                                </div>
                            </div>

                            <div className="form-format ws-3">
                                <label className="control-label">Accounting Period</label>
                                <div className="label-entries">
                                    <select name="accountingPeriod" onChange={handleInputChangeField} value={detailslist.accountingPeriod }>
                                        <option value="">Choose Accounting Period</option>
                                        {
                                            Reportings?.map((values:any ) => {

                                                return (
                                                    <option value={values.key} label={values.key}>{values.key}</option>
                                                )

                                            })
                                        }
                                    </select>
                                </div>
                            </div>

                            <div className="form-format ws-3">
                                <label className="control-label">Contract Basis</label>
                                <div className="label-entries">
                                    <select  name="contractType" onChange={handleInputChangeField} value={detailslist.contractType }>
                                        <option value="">Choose contract Type</option>
                                        {
                                            Basis?.map((values:any ) => {

                                                return (
                                                    <option value={values.key} label={values.value}>{values.value}</option>
                                                )

                                            })
                                        }
                                    </select>
                                </div>
                            </div>

                            <div className="form-format ws-3">
                                <label className="control-label">Contract Status</label>
                                <div className="label-entries">
                                    <select  name="contractStatus" onChange={handleInputChangeField} value={detailslist.contractStatus }>
                                        <option value="">Choose contract Status</option>
                                        {
                                            ContractStatus?.map((values:any ) => {

                                                return (
                                                    <option value={values.key} label={values.value}>{values.value}</option>
                                                )

                                            })
                                        }
                                    </select>
                                </div>
                            </div>

                            <div className="form-format ws-3">
                                <label className="control-label">Account Status</label>
                                <div className="label-entries">
                                    <select  name="accountStatus" onChange={handleInputChangeField} value={detailslist.accountStatus }>
                                        <option value="">Choose account Status</option>
                                        {
                                            StatusAccount?.map((values:any ) => {

                                                return (
                                                    <option value={values.key} label={values.value}>{values.value}</option>
                                                )

                                            })
                                        }
                                    </select>
                                </div>
                            </div>

                            <div className="form-format ws-3">
                                <label className="control-label">Account Extented Status</label>
                                <div className="label-entries">
                                    <select name="accountStatusExt" onChange={handleInputChangeField} value={detailslist.accountStatusExt }>
                                        <option value="">Choose account Status Ext</option>
                                        {
                                            StatusAccountExt?.map((values:any ) => {

                                                return (
                                                    <option value={values.key} label={values.value}>{values.value}</option>
                                                )

                                            })
                                        }
                                    </select>
                                </div>
                            </div>

                            <div className="form-format ws-3">
                                <label className="control-label">Statement Status</label>
                                <div className="label-entries">
                                    <select  name="statementCategory" onChange={handleInputChangeField} value={detailslist.statementCategory }>
                                        <option value="">Choose statement Category</option>
                                        {
                                            StatusStatement?.map((values:any ) => {

                                                return (
                                                    <option value={values.key} label={values.value}>{values.value}</option>
                                                )

                                            })
                                        }
                                    </select>
                                </div>
                            </div>

                            <div className='form-format ws-3'>
                                <label className="ws-12 text-bold">Start Date</label>
                                <div className="label-entries">
                                    <DatePickerRange onChange={dateSelectionStartDate} name={"Start Date"} type ={"single"} data={detailslist.contractStartDate}/>
                                </div>
                            </div>

                            <div className='form-format ws-3'>
                                <label className="ws-12 text-bold">End Date </label>
                                <div className="label-entries">
                                    <DatePickerRange onChange={dateSelectionEndate} name={"End Date"} type ={"single"} formate={"MMM d, yyyy"} data={detailslist.contractEndDate}/>
                                </div>                                
                            </div>

                            <div className='form-format ws-3'>
                                <label className="ws-12 text-bold">Signature Date</label>
                                <div className="label-entries">
                                    <DatePickerRange onChange={dateSelectionSignature} name={"Signature Date"} type ={"single"} formate={"MMM d, yyyy"} data={ detailslist.contractDate}/>
                                </div>                                
                            </div>
 
                            <div className='form-format ws-3'>
                                <label className="text-bold ws-12 forceSingleLine">Reporting Start Date </label>
                                <div className="label-entries">
                                    <DatePickerRange onChange={dateSelectionRSD} name={"Reporting Start Date"} type ={"single"} formate={"yyyyMMdd"} data={ detailslist.contractDate}/> 
                                </div>
                            </div>

                            <div className='form-format ws-3'>
                                <label className="ws-12 text-bold forceSingleLine">Reporting End Date </label>
                                <div className="label-entries">
                                    <DatePickerRange onChange={dateSelectionRED} name={"Reporting End Date"} type ={"single"} formate={"yyyyMMdd"} data={ detailslist.contractDate}/> 
                                </div>
                                
                            </div>

                            <div className="form-format ws-3">
                                <label className="control-label">Monthly Detail</label>
                                <div className="label-entries">
                                    <select id="basis" name="MonthlyDetail" onChange={handleInputChangeField} value={detailslist.MonthlyDetail}>
                                        <option value="">Choose Monthly Detail  </option> 
 
                                        {
                                            reportingName?.map((values:any ) => {

                                                return (
                                                    <option value={values.name} label={values.name}>{values.name}</option>
                                                )

                                            })
                                        }
                                     </select>
                                </div>
                            </div>

                            <div className="form-format ws-3">
                                <label className="control-label">Quarterly  Detail</label>
                                <div className="label-entries">
                                    <select id="basis" name="quarterlyDetail" onChange={handleInputChangeField} value={detailslist.quarterlyDetail}>
                                        <option value="">Choose Quarterly Detail</option> 
 
                                        {
                                            reportingName?.map((values:any ) => {

                                                return (
                                                    <option value={values.name} label={values.name}>{values.name}</option>
                                                )

                                            })
                                        }
                                     </select>
                                </div>
                            </div>

                            <div className="form-format ws-3">
                                <label className="control-label">Biannual Detail</label>
                                <div className="label-entries">
                                    <select id="basis" name="biannualDetail" onChange={handleInputChangeField} value={detailslist.biannualDetail}>
                                        <option value="">Choose Biannual Detail  </option> 
 
                                        {
                                            reportingName?.map((values:any ) => {

                                                return (
                                                    <option value={values.name} label={values.name}>{values.name}</option>
                                                )

                                            })
                                        }
                                     </select>
                                </div>
                            </div>                          

                            <div className="form-format ws-3 mtll"> 
                                <div className="form-format ws-6">
                                    <label className="control-label ws-8">No End Date</label>
                                    <div className="ws-3">
                                        <input type="checkbox" value={detailslist.noEndDate}/>
                                    </div>
                                </div>
    
                                <div className="form-format ws-6">
                                    <label className="control-label ws-8">In Perpetuity</label>
                                    <div className="ws-3">
                                        <input className="m0 mr" type="checkbox" data-ng-true-value="1" data-ng-false-value="0" id="inPerpetuity" checked={detailslist.inPerpetuity}/>
                                        
                                    </div>
                                </div>
    

                            </div>

                            <div className="form-format ws-9">

                                <div className="form-format ws-2">
                                    <label className="ws-12 mb">Royalty Contract</label>
                                    <div className="label-entries">
                                        <label className="switch mr">
                                            <input type="checkbox" onChange={()=>handleInputChangeField} name="royaltyContract"
                                                checked={detailslist.royaltyContract== 1} value={detailslist.royaltyContract}/>
                                            {detailslist.royaltyContract == 1 && <span className="slider round text-left white pm pts">Yes</span>}
                                            {detailslist.royaltyContract == 0 && <span className="slider round text-right white pm pts sliderOff">No</span>}                                        
                                        </label>
                                    </div>
                                </div>

                                <div className="form-format ws-2">                                       
                                    <label className="ws-12 mb">Collector Contract</label>
                                    <div className="label-entries">
                                        <label className="switch mr">
                                            <input type="checkbox" onChange={()=>handleInputChangeField} name="collectorContract"
                                                checked={detailslist.collectorContract== 1} value={detailslist.collectorContract}/>
                                            {detailslist.collectorContract == 1 && <span className="slider round text-left white pm pts">Yes</span>}
                                            {detailslist.collectorContract == 0 && <span className="slider round text-right white pm pts sliderOff">No</span>}                                        
                                        </label>
                                    </div>
                                </div>

                                <div className="form-format ws-2">                                        
                                    <label className="ws-12 mb">Publishing  Contract</label>
                                    <div className="label-entries">
                                        <label className="switch mr">
                                            <input type="checkbox" onChange={()=>handleInputChangeField} name="publishingContract"
                                                checked={detailslist.publishingContract== 1} value={detailslist.publishingContract}/>
                                            {detailslist.publishingContract == 1 && <span className="slider round text-left white pm pts">Yes</span>}
                                            {detailslist.publishingContract == 0 && <span className="slider round text-right white pm pts sliderOff">No</span>}                                        
                                        </label>
 
                                    </div>
                                </div>

                                <div className="form-format ws-2">                                       
                                    <label className="ws-12 mb">Mechanical Contract</label>
                                    <div className="label-entries">
                                       <label className="switch mr">
                                            <input type="checkbox" onChange={()=>handleInputChangeField} name="mechanicalContract"
                                                checked={detailslist.mechanicalContract== 1} value={detailslist.mechanicalContract}/>
                                            {detailslist.mechanicalContract == 1 && <span className="slider round text-left white pm pts">Yes</span>}
                                            {detailslist.mechanicalContract == 0 && <span className="slider round text-right white pm pts sliderOff">No</span>}                                        
                                        </label>

                                    </div>
                                </div>

                                <div className="form-format ws-3">
                                    <label className="ws-12 mb">Auto Generate ContractRefNum</label>
                                    <div className="label-entries"> 
                                        <label className="switch mr">
                                            <input type="checkbox" onChange={handleInputChangeField} name="autoGenContractRefNum"
                                                checked={detailslist.autoGenContractRefNum} value={detailslist.autoGenContractRefNum}/>
                                            {detailslist.autoGenContractRefNum == 1 && <span className="slider round text-left white pm pts">Yes</span>}
                                            {detailslist.autoGenContractRefNum == 0 && <span className="slider round text-right white pm pts sliderOff">No</span>}                                        
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className="form-format ws-8">
                                <label className="control-label">Default Link Track</label>
                                <div className="label-entries mt">
                                    <div className="ws-3"><input type="radio" onClick={handleInputChangeField} checked={detailslist.defaultLinkTrack==0} value="0" name="defaultLinkTrack"/>Contract Track</div>
                                    <div className="ws-3"><input type="radio" onClick={handleInputChangeField} checked={detailslist.defaultLinkTrack==1} value="1" name="defaultLinkTrack"/>Unlinked Tracks</div>
                                    <div className="ws-3"><input type="radio" onClick={handleInputChangeField} checked={detailslist.defaultLinkTrack==2} value="2" name="defaultLinkTrack"/>Tracks Already Linked</div>
                                    <div className="ws-3"><input type="radio" onClick={handleInputChangeField} checked={detailslist.defaultLinkTrack==3} value="3" name="defaultLinkTrack"/>Release Track</div>
                                </div>
                            </div>

                        </div>}                       

                    </div>

                    <div className="ws-12 box mtl">
                        <div className="box-header">
                            <h1>
                                <span className="pull-left cursor-hand"> Reporting</span>
                                <button className="btn-success hover-box btn-xs badge pl pr pull-right mr" onClick={()=>setClientProfileTypes('Reporting')}>
                                    <i className={ClientProfileTypes == 'Reporting' ? "icon-minus1" : "icon-plus1" } ></i>
                                </button>
                            </h1> 
                        </div> 

                        {ClientProfileTypes == 'Reporting' && <div className="box-content">
                            <div className="form-format ws-3">
                                <label className="control-label 12">Invoice Type</label>
                                <div className="label-entries ws-11">
                                    <select onChange={handleInputChangeField} value={""}>
                                        <option value="">Select Invoice Type</option>
                                        {invoiceTypes.map((inv) => <option key={inv.invoiceTypeIndex} value={inv.invoiceTypeIndex}>{inv.invoiceType}</option>

                                        )}
                                    </select>
                                </div>
                            </div>

                            <div className="form-format ws-3">
                                <label className="control-label 12"> Royalty CCs</label>
                                <div className="label-entries">
                                    <input type="text" value={detailslist.royaltyCc} name="royaltyCc" onChange={handleInputChangeField} className="form-control ws-12" placeholder="Enter Email for Royalty" />
                                </div>
                            </div>

                            <div className="form-format ws-3">
                                <label className="control-label 12">Mechanical CCs</label>
                                <div className="label-entries">
                                    <input type="text" value={detailslist.mechanicalCc} name="mechanicalCc" onChange={handleInputChangeField} className="form-control ws-12" placeholder="Enter Email for Mechanical" />
                                </div>
                            </div>

                            <div className="form-format ws-3">
                                <label className="control-label 12">Publisher CCs</label>
                                <div className="label-entries">
                                    <input type="text" className="form-control ws-12" value={detailslist.publisherCc} name="publisherCc" onChange={handleInputChangeField} placeholder="Enter Email for Publisher"></input>
                                </div>
                            </div>

                            <div className="ws-12">
                                <div className="form-format ws-3">
                                    <label className="control-label 12">Mail from</label>
                                    <div className="label-entries">
                                        <input type="text" value={detailslist.mailFrom} name="mailFrom" onChange={handleInputChangeField} className="form-control ws-12" placeholder="Enter Mail From" />
                                    </div>
                                </div> 

                                <div className="form-format ws-3">
                                    <label className="control-label 12">Negative Cycling</label>
                                    <div className="label-entries">
                                        
                                        <label className="switch mr">
                                            <input type="checkbox" onChange={handleInputChangeField} name="negativeCycling"
                                                checked={detailslist.negativeCycling == 1} value={detailslist.negativeCycling}  />
                                            {detailslist.negativeCycling == 1 && <span className="slider round text-left white pm pts">Yes</span>}
                                            {detailslist.negativeCycling == 0 && <span className="slider round text-right white pm pts sliderOff">No</span>}                                        
                                        </label>
    
                                    </div>
                                </div>

                                <div className="form-format ws-3">
                                    <label className="ws-12">Pay on Licenses only  </label>
                                    <div className="label-entries">                                  

                                        <label className="switch mr">
                                            <input type="checkbox" onChange={handleInputChangeField}  name="payLicenses" checked={detailslist.payLicenses== 1} value={detailslist.payLicenses}/>
                                            {detailslist.payLicenses == 1 && <span className="slider round text-left white pm pts">Yes</span>}
                                            {detailslist.payLicenses == 0 && <span className="slider round text-right white pm pts sliderOff">No</span>}                                        
                                        </label>

                                    </div>
                                </div>

                                <div className="form-format ws-3">
                                    <label className="ws-12">Use reduced CA Mechanical Rate  </label>
                                    <div className="label-entries">      
                                        <label className="switch mr">
                                            <input type="checkbox" onChange={handleInputChangeField}  name="useCaReduced"
                                                checked={detailslist.useCaReduced== 1} value={detailslist.useCaReduced}/>
                                            {detailslist.useCaReduced == 1 && <span className="slider round text-left white pm pts">Yes</span>}
                                            {detailslist.useCaReduced == 0 && <span className="slider round text-right white pm pts sliderOff">No</span>}                                        
                                        </label>
                                    </div>
                                </div>
                            </div>


                            <div className="form-format ws-3">
                                <label className="ws-12">Generate Blank Report In Statement </label>
                                <div className="label-entries">                                   

                                    <label className="switch mr">
                                        <input type="checkbox" onChange={handleInputChangeField}  name="generateBlankReportInStatement"
                                            checked={detailslist.generateBlankReportInStatement} value={detailslist.generateBlankReportInStatement}/>
                                        {detailslist.generateBlankReportInStatement && <span className="slider round text-left white pm pts">Yes</span>}
                                        {!detailslist.generateBlankReportInStatement && <span className="slider round text-right white pm pts sliderOff">No</span>}                                        
                                    </label>
                                </div>
                            </div>

                            <div className="form-format ws-3">
                                <label className="ws-12">Generate Excel Report In Statement  </label>
                                <div className="label-entries">                                    
                                    <label className="switch mr">
                                        <input type="checkbox" onChange={handleInputChangeField}  name="generateExcelReportInStatement"
                                            checked={detailslist.generateExcelReportInStatement} value={detailslist.generateExcelReportInStatement}/>
                                        {detailslist.generateExcelReportInStatement && <span className="slider round text-left white pm pts">Yes</span>}
                                        {!detailslist.generateExcelReportInStatement && <span className="slider round text-right white pm pts sliderOff">No</span>}                                        
                                    </label>
                                </div>
                            </div>

                            <div className="form-format ws-3">
                                <label className="ws-12">Only 1 Copy of 104 Pdf in statement </label>
                                <div className="label-entries"> 
                                    <label className="switch mr">
                                        <input type="checkbox" onChange={handleInputChangeField}  name="only1CopyOf104PdfInStatement"
                                            checked={detailslist.only1CopyOf104PdfInStatement== 1} value={detailslist.only1CopyOf104PdfInStatement}/>
                                        {detailslist.only1CopyOf104PdfInStatement == 1 && <span className="slider round text-left white pm pts">Yes</span>}
                                        {detailslist.only1CopyOf104PdfInStatement == 0 && <span className="slider round text-right white pm pts sliderOff">No</span>}                                        
                                    </label>
                                </div>
                            </div>

                            <div className="form-format ws-4">
                                <label className="control-label ws-12">Statement Filename Format</label>
                                <div className="label-entries mt">
                                    <div className="ws-12 m0">
                                        <input type="radio" name="a" value="1" checked={detailslist.statementFilenameFormat === 1} onChange={handleInputChangeField}  />
                                        <label className="ws-11">Period_ContractRef_ReportType (Default)</label>
                                    </div>

                                    <div className="ws-12 m0">
                                        <input type="radio" name="a" value="2" checked={detailslist.statementFilenameFormat  === 2} onChange={handleInputChangeField}  />
                                        <label className="ws-11">ContractRef_ContractTitle_ReportType_Period</label>
                                    </div>
                                </div>
                            </div>
                        
                        </div>}

                    </div>

                    <div className="ws-12 box mtl">
                        <div className="box-header">
                            <h1>
                                <span className="pull-left cursor-hand"> System</span>
                                <button className="btn-success hover-box btn-xs badge pl pr pull-right mr" onClick={()=>setClientProfileTypes('System')}>
                                    <i className={ClientProfileTypes == 'System' ? "icon-minus1" : "icon-plus1" } ></i>
                                </button>
                            </h1> 
                        </div>  

                        {ClientProfileTypes == 'System' && <div className="box-content">
                            <div className="form-format ws-3">
                                <label className="control-label ws-3" >Website</label>
                                <div className="label-entries">
                                    <input type="text" value={detailslist.clientWebsite} name="clientWebsite" onChange={handleInputChangeField} className="form-control ws-12" placeholder="Enter Website" />
                                </div>
                            </div>
                            <div className="form-format ws-3">
                                <label className="control-label ws-8" >Mech Costs Sales</label>
                                <div className="label-entries">
                                    <input type="text" value={detailslist.mechCostsSales} name="mechCostsSales" onChange={handleInputChangeField} className="form-control ws-12" placeholder="Mech Costs Sales" />
                                </div>
                            </div>

                            <div className="form-format ws-3">
                                <label className="control-label ws-8">DDEX Code</label>
                                <div className="label-entries">
                                    <input type="text" value={detailslist.DDEXPartyId} name="DDEXPartyId" onChange={handleInputChangeField} className="form-control ws-12" placeholder="DDEX Code" />
                                </div>
                            </div>

                            <div className="form-format ws-3">
                                <label className="control-label ws-3 forceSingleLine">DDEX Full Name</label>
                                <div className="label-entries">
                                    <input type="text" value={detailslist.DDEXFullName} name="DDEXFullName" onChange={handleInputChangeField} className="form-control ws-12" placeholder="DDEX Full Name" />
                                </div>
                            </div>

                            <div className="form-format ws-6">
                                <label className="control-label ws-6"> Default Territory / Currency </label>
                                <div className="label-entries">
                                    <div className="ws-3">
                                        {detailslist.homeTerritory && <button type="button" className="btn noCursor infoText btn-primary">{detailslist.homeTerritory}</button> }
                                        {detailslist.homeCurrency && <button type="button" className="btn noCursor infoText btn-primary">{detailslist.homeCurrency}</button> }
                                    </div>

                                    <div className="ws-8">
                                        <div className="label-entries ws-9">
                                            <input type="text" className="form-control" placeholder="Enter a Territory" id="currencyTerritory" value={detailslist.selectedTerritory} readOnly/>
                                        </div>

                                        <button className="btn-success"  onClick={() => setopenTerritoryDetail(true)}> Select  </button> 
                                    </div>

                                </div>
                            </div>

                            <div className="form-format ws-3 mtxl ">
                                <div className="label-entries">                                    
                                    <label className="ws-12 mb">Combined search for Common Search </label>
                                    <label className="switch mr">
                                        <input type="checkbox" onChange={handleInputChangeField}  name="searchFor" checked={detailslist.searchFor== 1} value={detailslist.searchFor}/>
                                        {detailslist.searchFor == 1 && <span className="slider round text-left white pm pts">Yes</span>}
                                        {detailslist.searchFor == 0 && <span className="slider round text-right white pm pts sliderOff">No</span>}                                        
                                    </label>
                                </div>
                            </div>
                        </div>}

                    </div>

                    <div className="box-footer pull-right">
                        <button className="btn btn-success btn-sm" onClick={handleFormSubmit}  type="submit"><i className="icon-save"></i> Update</button>
                    </div>
                </div>
            </div>

            {
                (() => {
                    if (openTerritoryDetail) {
                        return <SearchTerritory onSelectTerritory={handleTerritorySelection} open={openTerritoryDetail} selectedTerritory={detailslist.selectedTerritory} closeTerritoryDetail={closeTerritoryDetail}/>;
                    }

                })()
            }

        </div>
        
    )
}