import React, { useEffect, useState } from "react";
import Modal from "react-responsive-modal";
import clientProfileServices from "./clientProfileServices";

export default function DspDetail(param : any) {

    const [openFirst, setOpenFirst] = React.useState(false);

    const [saveAlert , setsaveAlert] = useState(false);
    const [emptyField , setemptyField] = useState(false);
    const [imageUpload , setimageUpload] = useState(false);
    const [dspTab , setDspTab] = useState(1);
    const [dspDetail, initdspDetail] = useState<any>([]);
    let [dspConnection, initdspConnection] = useState<any>([]);
    
    const returnValue = (e : any) => {
        param.onChange(e)
        setOpenFirst(false) 
    }

    useEffect(() => {

        if(param.param){ apiCall(); }
       
    }, []);

    const apiCall=() => {
        if(dspTab == 1){
            clientProfileServices.getListDSP(param.selected).then((res) => {
                initdspDetail(res[0])
            })

        }else if(dspTab == 2){
            clientProfileServices.getDSPConnectionDetails(param.selected).then((res) => {
                initdspConnection(res)
            })
        }

    }

    const DspConnection =()=>{
        clientProfileServices.getDSPConnectionDetails(param.selected).then((res) => {
            initdspConnection(res)
        })
    }

    const fieldHandle = (e:any)=>{
        let key = e.target.name
        dspDetail[key] = e.target.value;
        initdspDetail(dspDetail)
    }

    const fieldHandleConnection = (e:any)=>{
        let key = e.target.name
       /*dspConnection[key] = e.target.value;*/

        initdspConnection((prevDetails: any) => ({
            ...prevDetails,
            [key] :e.target.value
        }));

        //initdspConnection(dspConnection)
    }

    const [file , setFile] = useState<any>([]);
    const handleFileChange = (event: any) => {
       // const file = event.target.files && event.target.files[0];

       const fileName = event.target.files[0];
        if (fileName) {    
            dspDetail.src = URL.createObjectURL(fileName)
            initdspDetail(dspDetail)
            setFile(fileName)       
        }
    };

    const saveDsp = async () => {

        if(dspTab == 1){
            if(file){
               const uploadResponse = await clientProfileServices.uploadDSPLogo(file, dspDetail.code);
               if(uploadResponse){
                    clientProfileServices.saveDsp(dspDetail);
                }                
            }

        }else if(dspTab == 2){
            dspConnection.digitalServiceProviderDefaultId = dspDetail.digitalServiceProviderId
            dspConnection.digitalServiceProviderId = dspDetail.digitalServiceProviderId
            initdspConnection(dspConnection)
            clientProfileServices.saveDistributionListDSPConnectionDetails(dspConnection);
        }
    }

    return(

        <>
        <Modal open={param.param} onClose={() => {returnValue(1) ; }} center> 
            <div className="box m0">

                <div className="box-header well">
                    <h2> <i className="icon-music-notes pt pr"></i>DSP Details </h2>
                </div>

                <div className="box-content form-box">
                    <div className="form-horizontal">
                        <ul className="nav nav-tabs">
                            <li onClick={()=>{setDspTab(1 ); apiCall()}} className={dspTab==1?"active text-bold" : "text-bold "}>DSP Details</li>
                            <li onClick={()=>{setDspTab(2) ; DspConnection()}} className={dspTab==2?"active text-bold" : "text-bold "}>DSP Connection</li>
                        </ul>

                        <div >
                            <div className={dspTab==1?"":"hide"}>
                                <div className="box-content ">
                                    <div className="row-fluid">

                                        {saveAlert && <div className="alert alert-success">DSP saved successfully</div>}
                                        {emptyField && <div className="alert alert-danger">One or more fields are missing or incorrect.</div>}
                                        {imageUpload && <div className="alert alert-danger">Image not uploaded</div>}

                                        <div className="control-group m0 mtl">
                                            <label className="control-label span3 text-bold">DDEX Code*</label>
                                            <div className="controls span9 ml">
                                                <input type="text" name="DDEXCode" onChange={fieldHandle} className="form-control" value={dspDetail.DDEXCode} placeholder="DDEX Code" />
                                            </div>
                                        </div>

                                        <div className="control-group m0 mtl">
                                            <label className="control-label span3 text-bold">Full Name*</label>
                                            <div className="controls span9 ml">
                                                <input type="text" name="fullName" className="form-control" value={dspDetail.fullName} onChange={fieldHandle} placeholder="Full Name" />
                                            </div>
                                        </div>

                                        <div className="control-group m0 mtl">
                                            <label className="control-label span3 text-bold">Region*</label>
                                            <div className="controls span9 ml">
                                                <input type="text" value={dspDetail.region} onChange={fieldHandle}  name="region" className="form-control"  placeholder="Region"/>
                                            </div>
                                        </div>

                                        <div className="control-group m0 mtl hide">
                                            <label className="control-label span3 text-bold">DDEX version Accepted</label>
                                            <div className="controls span9">

                                                <select name="ddexVersion" id="ddexVersion">
                                                    <option value="" >-- Choose a DDEX version</option>
                                                    <option value="0" label="3.82">3.82</option>
                                                    <option value="1" label="4.1">4.1</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="control-group m0 mtl">
                                            <label className="controls span1 ">
                                                <input type="checkbox" className="checkbox-inline m0 mt pull-left" checked={dspDetail.isMusicDistributor} onChange={fieldHandle} />
                                            </label>
                                            <label className="control-label pl forceSingleLine text-bold pull-left"> Is Music Distributor</label>

                                        </div>

                                        <div className="row-fluid form-condensed mtl">
                                            <div className="span8">
                                                <label className="control-label span3 text-right text-bold">Upload Logo* :</label>
                                                <div className="controls span4 pt">
                                                    <label className="btn btn-success pull-left btn-sm">
                                                    <input type="file" accept="image/*" className="hide" onChange={handleFileChange} />
                                                        Choose file (*.png)                                                        
                                                    </label>
                                                </div>
                                            </div>

                                            <div className="span5">
                                                <img width="84" height="65" src={dspDetail.src} alt="Logo"/>
                                            </div>
                                        </div>

                                        <div className="span4">
                                            <div className="control-group">
                                                <div className="progress">
                                                        <div className="progress-bar " role="progressbar" ></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={dspTab==2?"":"hide"}>
                                <div className="box-content">
                                    <div className="row-fluid">
                                        
                                        <div className="control-group m0 mtl">
                                            <label className="control-label span3 text-bold">Connection Type</label>
                                            <div className="controls span9">
                                                <select name="connectionType" id="Select1" value={dspConnection?dspConnection.connectionType :""} onChange={fieldHandleConnection}>
                                                    <option value="" >-- Choose a Connection Type</option>
                                                    <option value="SFTP" label="SFTP">SFTP</option>
                                                    <option value="FTP" label="FTP">FTP</option></select>
                                            </div>
                                        </div>

                                        <div className="control-group m0 mtl">
                                            <label className="control-label span3 text-bold">Change Directory</label>
                                            <div className="controls span9 ml">
                                                <input type="text" name="changeDirectory" className="form-control" value={dspConnection?dspConnection.changeDirectory:""} onChange={fieldHandleConnection} placeholder="IP"/>
                                            </div>
                                        </div>

                                        <div className="control-group m0 mtl">
                                            <label className="control-label span3 text-bold">IP</label>
                                            <div className="controls span9 ml">
                                                <input type="text" name="HostorIP" className="form-control" id="Text4" value={dspConnection?dspConnection.HostorIP:""} onChange={fieldHandleConnection} placeholder="IP"/>
                                                <input type="text" name="IP" className="form-control hide" id="Text1" placeholder="IP"/>
                                            </div>
                                        </div>

                                        <div className="control-group m0 mtl">
                                            <label className="control-label span3 text-bold" >Username</label>
                                            <div className="controls span9 ml">
                                                <input type="text" name="username" className="form-control" value={dspConnection?dspConnection.username:""} onChange={fieldHandleConnection} placeholder="Username"/>
                                            </div>
                                        </div>

                                        <div className="control-group m0 mtl">
                                            <label className="control-label span3 text-bold" >Password</label>
                                                <div className="controls span9 ml">
                                                <input name="password" placeholder="Enter your password" type="password" onChange={fieldHandleConnection} value={dspConnection?dspConnection.password: ""}/>
                                            </div>
                                        </div>

                                        <div className="control-group m0 mtl">
                                            <label className="control-label span3 text-bold">Port</label>
                                            <div className="controls span9 ml">
                                                <input type="text" name="port" className="form-control" value={dspConnection?dspConnection.port :""} onChange={fieldHandleConnection} placeholder="Port"/>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="box-footer ">
                                <button className="btn btn-danger btn-sm" onClick={() => returnValue(2)}><i className="fa fa-close"></i> Close</button>
                                <button className="btn btn-info btn-sm" onClick={apiCall }><i className="fa fa-refresh"></i> Reset</button>
                                <button className="btn btn-success btn-sm" onClick={saveDsp}><i className="fa fa-save"></i> Save</button>
                            </div>
                        </div>


                    </div>

                </div>
            </div>
        </Modal>  
        </>
    )

}