
import React, { useEffect, useRef, useState } from "react"; 
import ReactQuill from "react-quill";

export default function TextEditor (param:any) {
 
    const [editorValue, setEditorValue] = useState(""); // State to hold the editor content
    

    useEffect(() => { 

        console.log(param)
        if(param && param.textvalue ){
            setEditorValue(param.textvalue )
        }

    }, []);


    const handleChange = (newValue :any) => {
        setEditorValue(newValue); // Update state on content change
        param.onchangeEditor(newValue)
    };
    
    return (
        <div>
            <ReactQuill className='hover-box'  value={editorValue}
                onChange={handleChange} placeholder={"Type something..."}
                modules={{
                toolbar: [
                    [{ font: [] }],
                    [{ header: [1, 2, 3] }],
                    ['bold', 'italic', 'underline', 'strike'],
                    [{ color: [] }, { background: [] }],
                    [{ script: 'sub' }, { script: 'super' }],
                    ['blockquote', 'code-block'],
                    [{ list: 'ordered' }, { list: 'bullet' }],
                    [{ indent: '-1' }, { indent: '+1' }, { align: [] }],
                    ['link', 'image', 'video'],
                    ['clean'], // Remove formatting button
                ],
                }}
            />
        </div>
    );
      

}