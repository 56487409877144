//import DatePicker from "react-datepicker";
import DatePicker from "react-datepicker"
//import "react-datepicker/dist/react-datepicker.css";
import React, { Suspense, useState, useEffect } from "react";
import { format } from "date-fns";

export default function TableDatePicker(param: any) {

  let [dateFormat, setdateFormat] = useState("MMM d, yyyy" );
  let [dateRange, setDateRange] = useState([null, null]);
  let [startDate, endDate] = dateRange;  
  
  //console.log(param)
  useEffect(() => { 

    if(param.formate){  setdateFormat(param.formate)  }

    if (param.data) { 
      const parsedDate : any= new Date(param.data);
      if (!isNaN(parsedDate.getTime())) {  
        setDateRange([parsedDate, null]);
      }
    }
    
  }, [])

  const dataReceiveSingle = (e: any) => {
     
    const parsedDate : any= new Date(e);
    setDateRange([parsedDate, null])
    param.onChange(e)
  }

  const dataReceive = (e: any) => {
    setDateRange(e)
    param.onChange(e)
  }

  return (
    <>
    { (() => {

      if (param.type != "range") { 

        return (        
        <div className="pull-left ws-12"> 
          <DatePicker 
              dateFormat={dateFormat}  dropdownMode="select"
              placeholderText="Select a date" showMonthDropdown
              todayButton={param.name?param.name : ""}
              showYearDropdown selected={startDate} onChange={(date: any) => dataReceiveSingle(date)}> 
          </DatePicker>
        </div>
        )

      }

      if (param.type == "range") { 

        return (
        
        <div className="pull-left ws-12">   
          <DatePicker dateFormat={dateFormat} placeholderText="Select Date From - To" 
              showDisabledMonthNavigation todayButton={param.name?param.name : ""}
              selectsRange={true}  startDate={startDate} endDate={endDate}
              isClearable={true} onChange={(update) => { dataReceive(update);  }} > 
          </DatePicker>          
        </div>
        )

      }
        
      })()
    }
    
    </>

  );
}