import React, { Suspense, useState, useEffect, useRef } from "react"; 
import { Modal } from 'react-responsive-modal'; 
import FileLogDetail from "./DataLoaderFileLogDetail"; 
import dataLoaderServices from "./DataLoaderServices";
import Pagination from "../Header/Pagination";


export default function DataLoaderFileLog(param: any) {

    const [openFirst, setOpenFirst] = React.useState(false);
    let [fileLogList , setfileLogList] = useState<any[]>([]);
    let [fileLogTotalRow , setfileLogTotalRow] = useState(0);
    const returnValue = (e: any) => {
        param.onChangeLogFile(e)
        setOpenFirst(false)
    }

    console.log(param)
    useEffect(() => {

        ApiCall();
    }, [param.open]);

    var payload = {
        pageNumber : 1 ,
        pageSize : 10 ,
        filename : "None"
    }
    const ApiCall = ()=>{

        if(searchFor.length>0){
            payload.filename = searchFor;
        }        
        dataLoaderServices.loadIFileLog(param.type , payload.filename , payload.pageNumber , payload.pageSize  ,'none').then((res: any) => {
            setfileLogTotalRow( res[0]?res[0].totalRows :0 )
            setfileLogList(res)
        })
    }

    const handleNameOnChange = (n: any) => {  
        payload.pageNumber = n.pageNumber;
        payload.pageSize =n.pageSize;
        ApiCall()
    }
    const [openLoadFile , setopenLoadFile] = useState(0)
    const [LoadFileStatus,setLoadFileStatus] = useState(false)

    let [fileLog , setfileLog] = useState<any[]>([]);
    const fileLogProgress =(e:any) =>{
        setfileLog(e)
        setLoadFileStatus(true)
        console.log(e)
    }

    const onChangeLoadFile = (e:any) =>{
        console.log(e)
        setLoadFileStatus(false)
    }


    const [searchFor,setsearchFor] = useState("")
    return (
        <div style={{ width: "40%", height: "120%" }}>

            <Modal open={param.open} onClose={() => returnValue(0)} >

                <div className="box-header-main ws-12">
                    <h2 className="ws-11"><i className="fa fa-question-circle"></i>View Upload File Log</h2>

                    <div className="ws-1 toolbox pt prxl">
                        <button className="btn btn-xs btn-pink pull-right" onClick={ApiCall}><i className="icon-refresh1"></i> Reset</button>
                    </div>
                </div>

                <div className="box-content">
                    <div className="form-horizontal  form-condensed clearfix ws-12">
                        <div className="row-fluid">
                            <div className="form-format ws-12">
                                <div className="ws-4">
                                    <label className="control-label">Filename</label>
                                    <div className="label-entries ws-12">
                                        <input type="text" className="form-control pLarge ws-12" placeholder="Enter Filename"  value={searchFor} onChange={(e=>setsearchFor(e.target.value))}  id="filename" />
                                    </div>
                                </div>

                                <div className="ws-1 mt ptl">
                                    <button className="btn btn-success mtl" onClick={ApiCall}><i className="fa fa-search"></i>Search </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row-fluid sortable" ng-show="filelogCount != null">
                    <div className="box span12">
                        {<div className="box-header well">
                            <h2> <i className="fa fa-table"></i> <span>{fileLogTotalRow} record(s) found</span>  </h2>
                        </div>}
                        <div className="box-content">
                            {(fileLogTotalRow ==0) && <div className="alert alert-info"> No records to display</div>}
                            {(fileLogTotalRow >0) && <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th >File</th>
                                            <th >Status</th>
                                            <th ng-if="logType != 'YT' &amp;&amp; logType != 'YR' &amp;&amp; logType != 'GP' &amp;&amp; logType != 'AZ'" >Template</th>
                                            <th >Date</th>
                                            <th >Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            fileLogList?.map((values:any)=>{
                                                return (
                                                    <tr  className={values.status == 0?"info" : "danger"}>
                                                        <td><a href="javascript:void(0)" ng-click="downloadFileLog(fileLog.originalFileName);" >{values.fileName}</a></td>
                                                        <td >{values.description}</td>
                                                        <td ng-if="logType != 'YT' &amp;&amp; logType != 'YR' &amp;&amp; logType != 'GP' &amp;&amp; logType != 'AZ'" >{values.tempIds}</td>
                                                        <td >{values.fileName}</td>
                                                        <td>
                                                            <button className="btn btn-success btn-xs" onClick={()=>{fileLogProgress(values);setopenLoadFile(1)}}><i className="icon-info"></i></button>
            
                                                        </td>
                                                    </tr>
                                                
                                                )
                                            })

                                        }
                                    </tbody>
                                </table>


                                <div className="ws-12 ptl">               
                                    {               
                                        (() => {
                                            if(fileLogTotalRow && fileLogTotalRow>10){
                                                return <Pagination onChange={handleNameOnChange} totalRows={fileLogTotalRow} />
                                            }
                                            
                                        })()
                                        
                                    }  
                                </div>

                            </div>}

                        </div>

                        
                        {
                            ( ()=>{

                                if(openLoadFile == 1){
                                    return (<FileLogDetail onChangeLoadFile={onChangeLoadFile} open={LoadFileStatus} type={fileLog}/>)
                                }

                            })()
                        }
                    </div>
                </div>

            </Modal>
        </div>
    )
}