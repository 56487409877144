import { CommonUtilities } from "../../../commonUtils";

export default class ClientProfileServices {

    //details
    static clientDetails(){
        var url = 'client/getClientDetails';
        return CommonUtilities.getData(url);
    }

    static saveClientDetails(data : any) {
        var url = '/client/saveClientDetails';
        return CommonUtilities.postData(url, data);
    }

    static saveClient(data : any){
        var url = '/client/saveClient';
        return CommonUtilities.postData(url, data);
    }

    static territoryCodes(){
        var url = 'contracts/territoryCode';
        return CommonUtilities.getData(url);
    }

    //reserves
    static getClient(){
        var url = 'client/get';
        return CommonUtilities.getData(url);
    }

    //ftp
    static ftpDetails(){
        var url = 'client/ftpdetails';
        return CommonUtilities.getData(url);
    }

    static saveFTPDetails = function (data : any) {
        var url = 'client/ftpdetails';
        return CommonUtilities.postData(url, data);
    };

    static getEmailUserTemplate(){
        var url = 'client/getEmailUserTemplat';
        return CommonUtilities.getData(url);
    }
    
    //Email Template
    static emailTemp(){
        var url = 'client/getEmailUserTemplate';
        return CommonUtilities.getData(url);
    }

    static saveEmailUserTemplate(data :any){
        var url = 'client/saveEmailUserTemplate';
        return CommonUtilities.postData(url, data);
    }

    //statement approval
    static users(param :any){
        var url = 'users?'+ CommonUtilities.buildQueryString(param , true , true);
        return CommonUtilities.getData(url) ;
    }

    static statementApprover(param :any){
        var url = 'approval/statementApprover/list?' + CommonUtilities.buildQueryString(param , true , true) ;
        return CommonUtilities.getData(url);
    }

    static statementApproverSave(data :any){
        var url = 'approval/statementApprover/save' ;
        return CommonUtilities.postData(url, data);
    }

    //Licensing
    static licensingDetails(){
        var url = 'client/get';
        return CommonUtilities.getData(url);
    }
    
    //zone management 
    static getAllZonesExcludingClient(){
        var url = 'contracts/zones/getAllZonesExcludingClient';
        return CommonUtilities.getData(url);
    }

    static getAllClientZones(data : any){
        var url = 'contracts/zones/getAllClientZones?isAllZone='+data.isAllZone;
        return CommonUtilities.getData(url);
    }

    static getClientTerritoriesZones(){
        var url = 'contracts/zones/getClientTerritoriesZones';
        return CommonUtilities.getData(url);
    }

    static territoryGroupsUnassignZone(){
        var url = 'contracts/territoryGroupsUnassignZone';
        return CommonUtilities.getData(url);
    }

    static searchZonesMechanical(data : any){
        var url = 'contracts/zones/searchZonesMechanical?zoneId='+data.isAllZone;
        return CommonUtilities.getData(url);
    }

    static updateClientZones (data:any){
        var url = 'contracts/zones/updateClientZones' ;
        return CommonUtilities.postData(url, data);
    }
    
    static saveZonesMechanical (data:any) {
        var url = 'contracts/zones/saveZonesMechanical' ;
        return CommonUtilities.postData(url, data);
    }

    static deleteZone(data:any){
        var url = 'contracts/zones/deleteZonesMechanical/'+ data.zmId
        return CommonUtilities.deleteData(url); 
    }
    //dsp management
    static getListDSP(data : any){
        var url = 'client/getListDSP?DigitalServiceProviderId='+data.digitalServiceProviderId;
        return CommonUtilities.getData(url);
    }

    static getDSPConnectionDetails(data : any){
        var url ='distributionList/dsp/getDSPConnectionDetails?dspId='+data.digitalServiceProviderId;
        return CommonUtilities.getData(url);
    }
    

    static getDSP(){
        var url = 'client/getDSP';
        return CommonUtilities.getData(url);
    }

    static getClientDSP(){
        var url = 'client/getClientDSP';
        return CommonUtilities.getData(url);
    }

    static getTerritory(data : any){
        var url = 'contracts/territoryListing/?territory='+ data;
        return CommonUtilities.getData(url);
    }

    static dspExport(){
        //var url = 'client/getTemplateExport';
        //return CommonUtilities.exportWithToken('GET', url, {}, '_blank', "DSP List");
        //window.open(apiUrlPrefix + 'client/getTemplateExport');
        CommonUtilities.exportVsWindow('client/getTemplateExport');
    }

    static saveDsp(data:any) {
        var url = 'client/saveDSP';
        return CommonUtilities.postData(url, data);
    }

    static saveDistributionListDSPConnectionDetails(data:any) {
        var url = 'distributionList/dsp/saveDistributionListDSPConnectionDetails';
        return CommonUtilities.postData(url, data);
    }
    
    static uploadDSPLogo(data:any , filename : any) {
        var url = 'client/uploadDSPLogo?fileName='+filename;
        return CommonUtilities.postUploadFileClientProfile(url, data);
    }

    static dspTemplateExport(){
        ///var url = 'client/getDSPTemplate';
        ///return CommonUtilities.exportWithToken('GET', url, {}, '_blank', "DSP Template");
        CommonUtilities.exportVsWindow('client/getDSPTemplate');
    }

    static updateDSPToClient(data:any){
        var url = 'client/updateDSPToClient';
        return CommonUtilities.postData(url, data);
    }

    static getClientImage(){
        var url = 'client/getClientImage';
        return CommonUtilities.getData(url);
    }


    static uploadLogo(file : any){
        var url = 'client/uploadLogo';
        return CommonUtilities.postUploadFileClientProfile(url, file);
    }

    static saveClientLogo(file: any){
        var url = 'client/saveLogo';
        return CommonUtilities.getData(url);
    }

    static getReports(){
        var url = 'reports/type1,type4,type6?isStatement=true&isReportMenu=true';
        return CommonUtilities.getData(url);
    }    

}