import { CommonUtilities } from '../../commonUtils';
import React, { useEffect, useState } from 'react';
import mechanicalServices from './mechanicalServices';
import DeleteDialogueBox from '../Common/deleteDialogue';
import Pagination from '../Header/Pagination';
import Header from '../Header/header';

export default function QuarterRun (subEntity:any) {

    const [ISRC, setISRC] = useState("");
    const [Periods, setPeriods] = useState("");
    const [Catalog, setCatalog] = useState("");
    const [ISRCCal, setISRCCal] = useState(false); 
    const [Publisher, setPublisher] = useState("");
    const [Collector, setCollector] = useState("");  
    const [calcFlag, setcalcFlag] = useState(false);  
    const [postmech, setpostmech] = useState(false);
    const [activeZone, setactiveZone] = useState("");
    const [calcAlert, setcalcAlert] = useState(false);  
    const [calcFlagTrue, setcalcFlagTrue] = useState(false);
    const [calcISRCtrue, setcalcISRCtrue] = useState(false);
    const [postmechtrue, setpostmechtrue] = useState(false);
    const [suspenseMech, setsuspenseMech] = useState(false);
    const [postmechfalse, setpostmechfalse] = useState(false);
    const [MechCalculate, setMechCalculate] = useState(false);
    const [calcFlagFalse, setcalcFlagFalse] = useState(false);
    const [totalCalculations,settotalCalculations] = useState(0);
    const [PeriodListing, setPeriodListing] = useState<any[]>([]);
    const [AllClientZones, getAllClientZones] = useState<any[]>([]);
    const [exportMessagetrue, setexportMessagetrue] = useState(false)
    const [exportMessagefalse, setcexportMessagefalse] = useState(false);
    const [ConmechanicalList, getConmechanicalList] = useState<any[]>([]);
    const [suspenseInitiationLoading, setsuspenseInitiationLoading] = useState(false);

    const onMechCalculate = (e:any) => {
        console.log(e)
        if(e == 1){
            if (Periods == '' || activeZone == '') {
                setcalcAlert(true);

                setTimeout(function(){ 
                    setcalcAlert(false);
                }, 5000);
                return;
            }else {
                mechanicalServices.calculate(Periods, activeZone).then(function (data) {
                    setcalcFlag(true);
                    setTimeout(function(){
                        setcalcFlag(false);
                    }, 5000);
                });
            }
        
        }
        setMechCalculate(false)
    }

    const onpostmech = (e:any) => {
        console.log(e)
        if(e == 1){
            console.log("delete")
            mechanicalServices.postMech(Periods, activeZone).then(function (rsp) {

                if (rsp != null && rsp.result == true) {

                    setpostmechtrue(true) 
                    setpostmechfalse(true);
                    
                    setTimeout(function(){ 
                        setpostmechtrue(false);
                    }, 3000); 
                }
                else {
                    setpostmechfalse(true);
                    setpostmechtrue(false) 

                    setTimeout(function(){ 
                        setpostmechfalse(false); 
                    }, 3000); 
                    
                }

            });
            //ClientProfileSevices.contractStatusdelete(deleteJob.territoryCode).then(()=> { apiCall ();})
        }
        setpostmech(false)
    }

    const onsuspenseMech= (e:any) => {
        console.log(e)
        if(e == 1){
            console.log("delete")
            mechanicalServices.suspenseInitiation(activeZone, Periods).then(function (url:any) {
                setsuspenseInitiationLoading(true)
                setTimeout(function(){ 
                    setsuspenseInitiationLoading(false); 
                }, 3000); 
            });
        }
        setsuspenseMech(false)
    }

    const onISRCCal= (e:any) => {
        console.log(e)
        if(e == 1){

            if (Collector == '' || ISRC == '') {

                setISRCCal(true);
                setTimeout(function(){ 
                    setISRCCal(false) ;
                }, 5000);
            }else{
                mechanicalServices.calcByISRC(Periods, Collector ,ISRC).then(()=>{

                })
            }

        }
        setISRCCal(false)
    }
    
    useEffect(() => { 
        mechanicalServices.getAllClientZones({isAllZone :false}).then((value)=>{
            getAllClientZones(value);
            if(activeZone.length == 0 && value.length > 0){
                setactiveZone(value[0].zoneID)
            }
        })

        mechanicalServices.periodCountList().then((value)=>{
            setPeriodListing(value);
            for(var $i=0 ; $i < value.length ; $i++){
                if( value[$i].periodCurrent == 1){
                    setPeriods(value[$i].periodDesc);
                }
                
            }
        })

        
    }, []);

    var mechPayload ={
        periodNum :20240331,
        groupingId:activeZone,
        pageSize:10,
        pageNumber:1
    };
    const searchMech =() =>{
        mechanicalServices.searchMech(mechPayload).then((value)=>{
            getConmechanicalList(value);
            settotalCalculations(value && value[0] && value[0].totalRow ? value[0].totalRow:0) 
        })
    };

    const handleNameOnChange = (n: any) => {
        mechPayload.pageNumber = n.pageNumber;
        mechPayload.pageSize = n.pageSize; 
        searchMech();
    };

    const calcISRC =() =>{

        if (Collector == '' || ISRC == '') {

            setcalcISRCtrue(true);

            setTimeout(function(){ 
                setcalcISRCtrue(false);
            }, 3000); 
        }
        else {
            setISRCCal(true)
        }

    };

    const calcReportISRC =() =>{

        if (Collector == '' || ISRC == '') {

            setcalcISRCtrue(true);
            setTimeout(function(){
                setcalcISRCtrue(false); 
            }, 5000);
        }
        else {
            var url = 'contracts/mechanical/isrcExportExcel?periodNum=' + Periods + '&collector=' + Collector + '&isrc=' + ISRC;
            CommonUtilities.exportVsWindow(url)
        }

    };

    const suspenseReportInitiation =() =>{
        var url = 'contracts/mechanical/suspenseExportExcel?periodNum=' + Periods + '&groupingId=' + activeZone;
        CommonUtilities.exportVsWindow(url)
    };

    const CMRRAExportLicenseRequest =() =>{
        var url = 'contracts/mechanical/CMRRALicenseRequest';
        CommonUtilities.exportVsWindow(url);
    };

    const CMRRAExport =() =>{
        var url = 'contracts/mechanical/CMRRAExportExcel?periodNum=' + Periods;
        CommonUtilities.exportVsWindow(url);
    };

    const openTxt404 =() =>{
        var url = 'contracts/mechanical/AP1Text?periodNum=' + Periods + '&groupingId=' + activeZone;
        CommonUtilities.exportVsWindow(url);
    };

    const openExcel404  =() =>{
        var url = 'contracts/mechanical/AP1Excel?periodNum=' + Periods + '&groupingId=' + activeZone;
        CommonUtilities.exportVsWindow(url);
    };

    const QuarterSalesGenerateExport  =() =>{

        var post = {
            'periodNum': Periods
        };

        mechanicalServices.quarterSalesGenerateExport(post).then(function (rsp:any) {
            if (rsp != null && rsp == true) {

                setexportMessagetrue(true);
                setcexportMessagefalse(false);

                setTimeout(function(){ 
                    setexportMessagetrue(false);
                }, 3000);
            }
            else {

                setexportMessagetrue(false);
                setcexportMessagefalse(true);

                setTimeout(function(){ 
                    setcexportMessagefalse(false);
                }, 3000);
 
            }
        });
    };

    const HFAExport =() =>{
        var url ='contracts/mechanical/HFAExportExcel?periodNum=' + Periods;
        CommonUtilities.exportVsWindow(url);
    };

    const HFAExportFilter =() =>{
        var param ={
            Periods : Periods,
            Publisher : Collector,
            Collector :Collector,
            Catalog :  Catalog,
            ISRC :ISRC
        }
        var url = 'contracts/mechanical/HFAExportFilterExcel?' + CommonUtilities.buildQueryString(param , true , true);
        CommonUtilities.exportVsWindow(url);
    };

    const exportMechanicalCalc =() =>{
        var param ={
            Periods : Periods,
            Publisher : Collector,
            Collector :Collector,
            Catalog :  Catalog,
            ISRC :ISRC
        }
        var pageSize = -1;
        var pageNumber = -1;

        var queryString = CommonUtilities.buildQueryString(param , true , true) + '&pageSize=' + pageSize + '&pageNumber=' + pageNumber;

        var url = 'contracts/mechanical/searchMechCalcExportExcel?' + queryString;
        CommonUtilities.exportVsWindow(url);

    }

    const exportByCollector =(all :any) =>{
        var param ={
            Periods : Periods,
            Publisher : Collector,
            Collector :Collector,
            Catalog :  Catalog,
            ISRC :ISRC
        }
        var temp = activeZone; 

        if (all) {  setactiveZone(''); }


        var queryString = CommonUtilities.buildQueryString(param , true , true);
        var url =  'contracts/mechanical/exportByCollector?' + queryString;
        CommonUtilities.exportVsWindow(url);

        if (all) {
            setactiveZone(temp) ;
        }

    };
    
    return (

        <>
        {(subEntity && !subEntity.subEntity) && <div>

            <Header Title={"Quarter Run"} />
            
            <div className="container-fluid p0">
                <div className="box hide"> <div className="box-header noBorderRadius bg-blue text-bold"><h2>Quarter Run</h2></div> </div>

                <div className="row-fluid box mt">
                    <div className="ws-12">
                        <ul className="nav nav-tabs mt">
                            {
                                AllClientZones?.map((value)=>{
                                    return ( 
                                        <li className={activeZone == value.zoneID ? "active" : ""}>
                                            <a href="javascript: void(0)" onClick={()=>setactiveZone(value.zoneID)}>{value.zoneID}</a>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>

                <div className="row-fluid">

                    <div className=" box ws-12 mt">
                        <div className="box-header well"> <h2> <i className="icon-search"></i> Mechanical Search  </h2> </div>
                        
                        <div className="box-content">
                            <div className="row-fluid search-form-box">                                
                                {calcISRCtrue && <div className="alert alert-danger mt" >Please fill Collector & ISRC</div>}
                                {postmechtrue &&<div className="alert alert-info mt">Operation successfully queued for processing</div>}
                                {postmechfalse &&<div className="alert alert-warning mt">Re-run this operation after a few mins as same operation already in progress</div>}

                                <div className="form-format ws-6">
                                    <label className="control-label ws-3 text-right pr pt" >Periods</label>
                                    <div className="label-entries ws-9">
                                        <select className="form-control" id="period" value={Periods} onChange={(e)=>setPeriods(e.target.value)}>

                                            {
                                                PeriodListing?.map((value:any)=>{
                                                    return (
                                                        <option className={value.periodOpen == 2 ?  "btn": "btn-success opacityDisabled"} value={value.periodDesc}>
                                                            {CommonUtilities.dateNameFilter(value.periodFrom)  + " - "  + CommonUtilities.dateNameFilter(value.periodTo)}
                                                        </option>
                                                    )

                                                })
                                            }
                                        </select>
                                    </div>
                                </div>

                                <div className="form-format ws-6">
                                    <label className="control-label ws-3 text-right pr pt">Publisher</label>
                                    <div className="label-entries ws-9">
                                        <input type="text" placeholder="Publisher" id="Publisher" value={Publisher} onChange={(e)=>setPublisher(e.target.value)}/>
                                    </div>
                                </div>

                                <div className="form-format ws-6">
                                    <label className="control-label ws-3 text-right pr pt">Collector</label>
                                    <div className="label-entries ws-9">
                                        <input type="text" placeholder="Collector" id="Collector" value={Collector} onChange={(e)=>setCollector(e.target.value)} />
                                    </div>
                                </div>

                                <div className="form-format ws-6">
                                    <label className="control-label ws-3 text-right pr pt">Catalog</label>
                                    <div className="label-entries ws-9">
                                        <input type="text" placeholder="Catalog" id="Catalogue" value={Catalog} onChange={(e)=>setCatalog(e.target.value)} />
                                    </div>
                                </div>

                                <div className="form-format ws-6">
                                    <label className="control-label ws-3 text-right pr pt">ISRC</label>
                                    <div className="label-entries ws-9">
                                        <input type="text" placeholder="ISRC" id="ISRC" value={ISRC} onChange={(e)=>setISRC(e.target.value)} />
                                    </div>
                                </div>
                                
                            </div>
                        </div>

                        <div className="box-footer">

                            <button className="btn btn-success pull-right"  onClick={searchMech}> <i className="icon-search"></i> Search </button>

                            <button className="btn btn-pink pull-right" data-ng-click="clearCalcForm()">
                                <i className="icon-reload"></i> Reset
                            </button>
                            <button className="btn btn-success toolbox pull-left" onClick={()=>setMechCalculate(true)}>
                                <i className="icon-calculator"></i> Calculate
                            </button>
                            <button className="btn btn-warning pull-left" onClick={()=>setpostmech(true)}> <i className="icon-tick"></i> Post Mechs </button>

                            <button className="btn btn-warning pull-left hide">
                                <i className="fa file-excel"></i> Suspense 
                               {suspenseInitiationLoading && <span>- (Push in pending licenses started)</span>}
                            </button>

                            <button className="btn btn-warning pull-left" onClick={()=>setsuspenseMech(true)}>
                                <i className="fa file-excel"></i> Suspense 
                                {suspenseInitiationLoading && <span ng-show="suspenseInitiationLoading" className="hide">- (Push in pending licenses started)</span>}
                            </button>

                            <a className="btn btn-primary pull-left" onClick={calcISRC}> <i className="fa file-excel"></i> Calc by ISRC </a>

                        </div>                    
                    </div>

                    <div className="box ws-12 mt">
                        <div >
                            <div className="box-header well"> <h2><i className="ficon-calculator"></i> Mechanical Search Result {totalCalculations} record(s) found</h2> </div>

                            <div className="box-content">
                                {calcAlert && <div className="alert alert-danger" >Please select a period and a region to proceed</div>}
                                {calcFlagTrue && <div className="alert alert-info" >Calculation queued for processing</div>}
                                {calcFlagFalse && <div className="alert alert-warning" >Re-run this operation after a few mins as similar job already in progress</div>}
                                {totalCalculations == 0 && <div className="alert alert-info">No data to display yet</div>}
                                {exportMessagetrue && <div className="alert alert-warning" >Quarter Sales generation has been queued for operation. Check 
                                    <a ng-click="openJobStatus()">Job status</a> for progress.
                                </div>}
                                {exportMessagefalse && <div className="alert alert-warning" >Unable to process Quarter Sales Generation</div>}

                                <div className="table-responsive">
                                    {totalCalculations > 0 && <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th>Collector name</th>
                                                <th>Publisher</th>
                                                <th>Writer</th>
                                                <th>Cat log Number</th>
                                                <th>ISRC Work Number</th>
                                                <th>Perc split</th>
                                                <th>Artist</th>
                                                <th>Units</th>
                                                <th>Formats/Source Codes</th>
                                                <th>Penny rate</th>
                                                <th>Mech rate</th>
                                                <th>Mech amount %</th>
                                                <th>Territory Code</th>
                                                <th>Track Work Name</th>
                                            </tr>
                                        </thead>
                                        <tbody> 
                                            {
                                                ConmechanicalList?.map((value:any)=>{
                                                    return (
                                                        <>
                                                        <tr>
                                                            <td>{value.collectorName}</td>
                                                            <td>{value.publisher}</td>
                                                            <td>{value.writer}</td>
                                                            <td>{value.catLogNumber}</td>
                                                            <td>{value.iSrCworkNumber}</td>
                                                            <td>{value.percSplit}</td>
                                                            <td>{value.artist}</td>
                                                            <td>{value.units}</td>
                                                            <td>{value.format}</td>
                                                            <td>{value.pennyRate}</td>
                                                            <td>{value.mechRate}</td>
                                                            <td>{value.mechAmountPer}</td>
                                                            <td>{value.territoryCode}</td>
                                                            <td>{value.trackWorkName}</td>
                                                        </tr>                                                        
                                                        </>
                                                    )
                                                })
                                            }                                           
                                            
                                        </tbody>
                                    </table>}
                                </div>

                                <div className="box-footer bg-white pt">
                                {
                                    (() => {
                                        if (totalCalculations > 1 && totalCalculations > 10) {
                                            return <div className="pll ml"> <Pagination onChange={handleNameOnChange} totalRows={totalCalculations} /></div>
                                        }

                                    })()

                                }
                                </div>
                            </div>

                            <div className="box-footer bg-white">

                                {Periods && <div className="hover-box pull-right mts">
                                    <span className={"icon-"+activeZone}> </span>
                                    <button className="btn-success"> Report 404</button>
                                    <div className="hover-block" style={{marginTop: "25px!important"}}>
                                        <a className="hover-box btn btn-xs btn-app btn-success btn-xs pull-left mr" onClick={openTxt404} > TXT</a>
                                        <a className="hover-box btn btn-xs btn-app btn-success btn-xs pull-left mr" onClick={openExcel404}>EXCEL</a>
                                    </div>
                                </div>}

                                {Periods && (activeZone == 'UK' ) &&  <a className="btn btn-primary pull-right clearfix  mts" 
                                    href={"/CrystalReport/CrystalReport403.aspx?template=Reports/403%20Mechancial%20Summary.rpt&periodNum="+Periods+"&zone="+activeZone}
                                    ng-hide="(calc.periodNum && calc.groupingId == 'UK') || true">
                                    <span className={"icon-"+activeZone}></span> Report 403
                                </a>}

                                {Periods && (activeZone != 'UK' )&& <a href={"/CrystalReport/CrystalReport402.aspx?template=Reports/402%20Publisher%20and%20Collector%20Summary.rpt&periodNum="+Periods+"&groupingid="+activeZone}
                                    className="btn btn-success pull-right  mts">
                                    <span className="icon-US"> </span>Report 402
                                </a>}

                                <div className="hover-box pull-right mts">
                                    <button className="btn btn-success"><i className="icon-file-add"></i> Mech Report 401/411</button>
                                    <div className="hover-block" style={{marginTop: "25px!important"}}>

                                        <a href={"/CrystalReport/Report401.aspx?template=Reports/401Report.rpt&periodNum="+Periods}
                                            className="hover-box btn btn-xs btn-app btn-success btn-xs pull-left mr">
                                            <span className={"icon-"+activeZone}>  </span> Report 401 for all zones
                                        </a>

                                        <a href={"/CrystalReport/Report401.aspx?template=Reports/Crystal411.rpt&periodNum="+Periods} 
                                            className="hover-box btn btn-xs btn-app btn-success btn-xs pull-left">
                                            <span className={"icon-"+activeZone}></span>Report 411 for all zones
                                        </a>

                                    </div>
                                </div>

                                <a className="btn btn-primary btn-xs pull-right  mts hide"> <span className={"icon-US"+activeZone}></span>Report 401 </a>

                                <div className="hover-box mt pull-left">
                                    <a className="btn-success" href={"/CrystalReport/ExportToExcel.aspx?template=QuarterSalesOutput&groupingid="+activeZone }style={{color: "white"}}>
                                        <i className="icon-file-add"></i> Quarter Sales
                                    </a>
                                    <div className="hover-block" style={{marginTop: "25px!important"}}>
                                        <a className="hover-box btn btn-xs btn-app btn-success btn-xs pull-left" onClick={QuarterSalesGenerateExport} > Generate </a>
                                    </div>
                                </div>

                                <a className="btn btn-success pull-left  mts" onClick={CMRRAExport}><i className="icon-file-add"></i> CMRRA Export </a>

                                <a className="btn btn-success pull-left  mts" onClick={CMRRAExportLicenseRequest}><i className="icon-file-add"></i> CMRRA License Request  </a>
                                
                                <div className="hover-box mt pull-left">
                                    <button className="btn-success"><i className="icon-file-add"></i> HFA Export</button>
                                    <div className="hover-block" style={{marginTop: "25px!important"}}>
                                        <a className="hover-box btn btn-xs btn-app btn-success btn-xs pull-left mr" onClick={HFAExport}> All </a>
                                        <a className="hover-box btn btn-xs btn-app btn-success btn-xs pull-left" onClick={HFAExportFilter}> By search criteria </a>
                                    </div>
                                </div>
                                                                
                                <button className="btn btn-warning pull-left mts mls" onClick={suspenseReportInitiation}>
                                    <i className="fa file-excel"></i> Suspense Report <span ng-show="suspenseReportInitiationLoading" className="hide">- (Export Suspense Report started)</span>
                                </button>
                                
                                <button className="btn btn-warning pull-left mts mls hide" onClick={suspenseReportInitiation}>
                                    <i className="fa file-excel"></i> Suspense Report <span ng-show="suspenseReportInitiationLoading" className="ng-hide">- (Export Suspense Report started)</span>
                                </button>

                                <button className="btn btn-primary pull-left mts mls" onClick={calcReportISRC}> <i className="fa file-excel"></i> Calc by ISRC Report   </button>

                                {(totalCalculations > 0) && <button className="btn-success hover-box btn mts pull-right" onClick={exportMechanicalCalc}>
                                    <i className="icon-file-add"></i> MechCalc Export
                                </button>}

                                {(totalCalculations > 0 && false) && <button className="btn btn-success mts pull-right" onClick={exportByCollector}>
                                    <i className="icon-download1"></i>Export by collector name
                                    {(totalCalculations > 5000) && <span className="label label-danger">might take a while</span>}
                                </button>}

                                {(totalCalculations > 0) && <div className="hover-box mts pull-right">
                                    <button className="btn-success" onClick={()=>exportByCollector(false)}><i className="icon-file-add"></i> Export by collector name</button>
                                    {(totalCalculations > 5000) && <span className="label label-danger">might take a while</span>}                                    
                                </div>}

                                {(totalCalculations > 0) && <div className="hover-box mts pull-right">
                                    <button className="btn-success" onClick={()=>exportByCollector(false)}><i className="icon-file-add"></i> </button>
                                    {(totalCalculations > 5000) && <span className="label label-danger" data-ng-show="totalCalculations > 5000">might take a while</span>}                                    
                                </div>}
                            </div>

                        </div>
                    </div>
                </div>
            </div> 

            {
                (()=>{

                    if (MechCalculate) {
                        return <DeleteDialogueBox param={true} message={"run the calculation " } onChange={onMechCalculate} />
                    } 
                    
                    if (postmech) {
                        return <DeleteDialogueBox param={true} message={"run post mechs " } onChange={onpostmech} />
                    } 

                    if (suspenseMech) {
                        return <DeleteDialogueBox param={true} message={"run the suspense " } onChange={onsuspenseMech} />
                    } 

                    if (ISRCCal) {
                        return <DeleteDialogueBox param={true} message={"run the calculation by ISRC " } onChange={onISRCCal} />
                    } 
                })()
            }
        </div>}
        </>
    )
}