import { CommonUtilities }  from '../../commonUtils';

export default class mechanicalServices {

    static periodCountList() {
        var url = 'common/periodCount'
        return CommonUtilities.getData(url);
    }

    static getAllClientZones(param:any) {
        var url = 'contracts/zones/getAllClientZones?'+ CommonUtilities.buildQueryString(param , true , true);
        return CommonUtilities.getData(url);
    }

    //https://staging.onkorrect.com/Api/contracts/mechanical/searchMech?periodNum=20240331&groupingId=US&pageSize=10&pageNumber=1    
    static searchMech(param:any) {
        var url = 'contracts/mechanical/searchMech?'+ CommonUtilities.buildQueryString(param , true , true);
        return CommonUtilities.getData(url);
    }

    static calcByISRC(periodNum :any, collector :any, isrc :any) {
        var url = 'contracts/mechanical/calcByISRC?periodNum=' + periodNum + '&collector=' + collector + '&isrc=' + isrc;

        return CommonUtilities.getData(url);
    };

    static quarterSalesGenerateExport (param:any) {
        var url = 'contracts/mechanical/quarterSalesGeneration';
        return CommonUtilities.postData(url, param);
    };

    static exportByCollector (param:any) {
        var url =  'contracts/mechanical/exportByCollector?' + CommonUtilities.buildQueryString(param , true , true);
        return CommonUtilities.getData(url);
    };
    
    static postMech (period :any, region :any){

        var url = '/contracts/mechanical/postMechs?periodNum=' + period + '&groupingId=' + region;
        return CommonUtilities.getData(url);

    };

    static suspenseInitiation = function (groupingId :any, periodNum :any) {
        var url = 'contracts/mechanical/suspense?periodNum=' + periodNum + '&groupingId=' + groupingId;
        return CommonUtilities.getData(url);
    };

    static calculate = function (period :any, region :any) {
        var url = 'contracts/mechanical/calculateMech?' +'periodNum=' + period +'&groupingId=' + region;
        return CommonUtilities.getData(url);
    };

}