import React, { Suspense, useState, useEffect } from "react";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import ClientProfileServices from "../clientProfileServices";

export default function SearchTerritory( param :any) {

    const [openFirst, setOpenFirst] =  useState(false);
    const [territoryValue, setTerritoryValue] = useState("");
    const [territoryData, setTerritoryData] = useState<any[]>([]);
    const [selectedTerritoryName, setSelectedTerritoryName] = useState(""); 
   
    useEffect(() => {       
        if(param.selectedTerritory){
            setSelectedTerritoryName(param.selectedTerritory);
        }
        
    }, [param]);

    const searchTerritories = () => { 
        ClientProfileServices.getTerritory(territoryValue) .then(response => {
            setTerritoryData(response); 
        })   
    }  


    const onSelectTerritory = (post :any) => {
        setOpenFirst(false); 
        param.onSelectTerritory(post.territoryName, post.territoryCode, post.territoryCurrency);
    }

    const closeTerritoryDetail = () =>{
        param.closeTerritoryDetail()
    }
    return (
        <div className='pull-left' >
            <Modal open={param.open} onClose={closeTerritoryDetail} center> 
                <div className="box">
                    <div className="row-fluid sortable">
                        <div className="box-header-main">  <h2>Search Territory</h2> </div> 

                        <div className="box-content">
                            <div className="form-horizontal  form-condensed clearfix">
                                <div className="row-fluid ws-10">

                                    <div className="ws-5">
                                        <div className="control-group">
                                            <label className="control-label text-bold">Territory Code/Currency/Name</label>
                                            <div className="controls">
                                                <input type="text" className="ws-12 form-control" placeholder="Enter Territory Code/Currency/Name"
                                                    value={territoryValue} onChange={(e) => setTerritoryValue(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="ws-3 mt">
                                        <a className="btn btn-small btn-success mtl" onClick={searchTerritories}><i className="fa fa-search"></i>Search </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="row-fluid sortable">
                        <div className="box span12">
                            <div className="box-header well">
                                <h2> <i className="fa fa-table"></i> <span> </span>  </h2>
                            </div>
                            <div className="box-content">
                                {territoryData.length === 0 ? (<div className="alert alert-info">No records to display</div>
                                ) : (

                                    <div className="table-responsive">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>Territory Name</th>
                                                    <th>Territory Code</th>
                                                    <th>Territory Currency</th>
                                                    <th>Operation</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {territoryData.map((post, index) => (
                                                    <tr key={index}>
                                                        <td>{post.territoryName}</td>
                                                        <td>{post.territoryCode}</td>
                                                        <td>{post.territoryCurrency}</td>
                                                        <td>
                                                            <a className="btn btn-primary" onClick={() => {onSelectTerritory(post); setSelectedTerritoryName(post.territoryName); }}>
                                                                <i className="icon-th icon-white"></i>Select
                                                            </a>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>)

                                }

                                <div className="clearfix">

                                </div>
                            </div>

                        </div>

                    </div>

                </div>
                
            </Modal>
        </div>
    );
}