import React, { useState, useEffect } from 'react';
import Drawer from 'react-modern-drawer';
import commonServices from "./commonServices";
import { CommonUtilities } from "../../commonUtils";
import Pagination from "../../components/Header/Pagination";

export default function MonitoringSlider(event: any) {

    let [execListing, setexecListing] = useState<any>([]);
    let [execHeader, setexecHeader] = useState<any>([]);
    let [totalRows, setTotalRows] = useState(0);

    const toggleDrawer = () => { event.CloseDetail(true) }
    const Data: any = { pageNumber: 1, pageSize: 10 }

    const Search = () => {
        commonServices.MonitorDetail({ ErrorName: event.errorName, TestFor: event.MainButton, PageNumber: Data.pageNumber, PageSize: Data.pageSize })
            .then((values) => {
                setexecListing(CommonUtilities.relativeDataRemapping(values.data, values.headers.original))
                setTotalRows(values.totalRows)
                execHeader = [];
                Object.entries(values.headers.original).forEach(([key, value]) => { execHeader.push(value) });
                setexecHeader(execHeader)
            })
    }

    const handleNameOnChange = (n: any) => {
        Data.pageNumber = n.pageNumber;
        Data.pageSize = n.pageSize;
        Search();
    }

    useEffect(() => {
        Search();
    }, []);

    return (<>

        <Drawer open={event.openDetail}
            onClose={toggleDrawer}
            direction='right'
            className='react-drawer-left full-height-vh allowHscroll' style={{ width: "100%" }}>

            <div className='box ws-6 pull-right' style={{ height: "100%"}}>

                <div className="ws-12 pull-right">

                    <div className="box-header  noBorderRadius bg-blue ws12">
                        <h1 style={{ color: "White" }}>{event.errorName}</h1>
                    </div>

                    <div className="ws-12 pull-left box pll">
                        <div className="table-responsive mrl allowVscroll">

                            <table className="table mbxl mtl">
                                <tr>
                                    {
                                        execHeader.map((values: any, index: any) => {
                                            if (values != 'totalRow') { return (<> <th> {values} </th> </>) }
                                        })
                                    }
                                </tr>

                                {
                                    execListing.map((value: any) => {
                                        return (<>
                                            <tr>
                                                {
                                                    execHeader.map((Hvalues: any, index: any) => {
                                                        if (Hvalues != 'totalRow') { return (<> <td> {value[Hvalues]} </td> </>) }
                                                    })
                                                }

                                            </tr>
                                        </>)
                                    })
                                }
                            </table>
                        </div>

                        <div className="ws-12">
                            {
                                (() => {
                                    if (totalRows && totalRows > 10) {
                                        return <Pagination onChange={handleNameOnChange} totalRows={totalRows} />
                                    }
                                })()
                            }</div>
                    </div>

                    <div className="box-footer alignBottom">
                        <button className="btn-danger pull-right" onClick={() => Search()}>Search</button>
                        <button className="btn-danger pull-right" onClick={() => toggleDrawer()}>Close</button>
                    </div>
                </div>
            </div>
        </Drawer>

    </>)

}