import { apiUrlPrefix } from "./settings";
import ReactDOM from 'react-dom';
import { format } from "date-fns";
import ScrapingTool from "./components/Scraping/ScrapingTool";
import DirectoriesIndex from "./components/directories/directoriesIndex";
import React, { useState, useEffect } from 'react';
import DataLoader from "./components/DataLoading/DataLoader";
import QuarterRun from "./components/Mechanical/quarterRun";

export class CommonUtilities {


    static mapQueryParams(queryParams: string) {
        return queryParams
            ? Object.keys(queryParams).map(function (key) {
                return key;// + '=' + queryParams[key]
            }).join('&')
            : ""
    }

    static getAuthAccess() {
        var authToken = { email: '', userId: '' };

        if (window.sessionStorage.getItem('loggedUserRight')) {
            authToken = JSON.parse(window.sessionStorage.getItem('loggedUserRight') as string);
        }
        return authToken;

        if (authToken) {
            return authToken;
        } else {
            this.clearAuthToken();
        }

    }

    static getAuthToken() {
        var authToken = '';

        if (window.sessionStorage.getItem('loggedUserRight')) {
            authToken = JSON.parse(window.sessionStorage.getItem('loggedUserRight') as string).token;
        }
        return authToken;

        if (authToken) {
            return authToken;
        } else {
            this.clearAuthToken();
        }

    }

    static setAuthToken(userDetails: any) {

        //const [myArray, updateMyArray] = useState([]);

        console.log(userDetails.userEntityRight[0]);
        console.log(JSON.stringify(userDetails));


        if (!JSON.stringify(userDetails)) {
            this.clearAuthToken();
        } else {

            /* var system = {
                 entityName: "System",
                 entityType: 1,
                 subEntities:
                     [{
                         entityType: 1,
                         isActive: 1,
                         subEntity: "Home",
                         subEntityCode: "home",
                         subEntityID: 1,
                         subEntityRef: 1
                     },  {
                         entityType: 1,
                         isActive: 1,
                         subEntity: "Reports",
                         subEntityCode: "Grid_Reporting",
                         subEntityID: 1,
                         subEntityRef: 1
                     }, {
                         entityType: 1,
                         isActive: 1,
                         subEntity: "Uploader",
                         subEntityCode: "download",
                         subEntityID: 1,
                         subEntityRef: 1
                     }/*, {
                         entityType: 8,
                         isActive: 1,
                         subEntity: "Affiliate",
                         subEntityCode: "Affiliate",
                         subEntityID: 18,
                         subEntityRef: 2622144
                     },{
                         entityType: 1,
                         isActive: 1,
                         subEntity: "Directories",
                         subEntityCode: "Portal_Directories",
                         subEntityID: 1,
                         subEntityRef: 1
                     }]
             };*/

            var temp = userDetails.userEntityRight;
            userDetails.userEntityRight = [];
            //userDetails.userEntityRight.push(system);
            userDetails.userEntityRight.push(...temp);

            //const [names, setNames] = useState(['Alice', 'Bob']);
            //setNames(current => [...current, ...arr]);

            window.sessionStorage.setItem('loggedUserRight', JSON.stringify(userDetails));
            window.sessionStorage.setItem('loggedInUser', JSON.stringify(userDetails));

            window.location.reload();
        }
    }

    static clearAuthToken() {
        window.sessionStorage.removeItem('loggedUserRight');
        window.sessionStorage.removeItem('loggedInUser');
        window.location.reload();
    }

    static async dataPassingHandler(data: any) {
        this.blockUiLoading(false);
        return data.json();
    }

    static async getData(url: string) {
        try {

            this.blockUiLoading(true);

            let response = fetch(apiUrlPrefix + url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'X-AuthToken': this.getAuthToken()
                }
            })

            response.then(status => {
                // console.log(status.status);
                //console.log(status);
                if (status.status && status.status == 401) {
                    this.clearAuthToken();
                }
            });
            //console.log(response)

            return (this.dataPassingHandler(await response));


        } catch (error) {
            this.blockUiLoading(false);
            console.log('error Api get');
            this.clearAuthToken();
            return null;
        }
    }

    static async getDataThirdParty(url: string, data: any) {

        url = url + data;
        console.log(url);

        try {

            this.blockUiLoading(true);

            let response = fetch(url, {
                headers: {
                    //"Access-Control-Allow-Origins": "*",
                    //"Access-Control-Allow-Headers": "Content-Type",
                    //"Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'X-AuthToken': this.getAuthToken()
                }
            })

            response.then(status => {
                // console.log(status.status);
                //console.log(status);
                if (status.status && status.status == 401) {
                    //this.clearAuthToken();
                }
            });
            //console.log(response)

            return (this.dataPassingHandler(await response));


        } catch (error) {
            this.blockUiLoading(false);
            console.log('error Api get');
            //this.clearAuthToken();
            return null;
        }
    }

    static blockUiLoading(startStop: boolean) {
        var element = (<div></div>);

        if (startStop) {
            element = (
                <div>
                    <div className="loader">
                        <div className="bar1"></div>
                        <div className="bar2"></div>
                        <div className="bar3"></div>
                        <div className="bar4"></div>
                        <div className="bar5"></div>
                        <div className="bar6"></div>
                    </div>
                    <div id="block-ui-message-container">
                        &nbsp;
                    </div>
                </div>
            );
        }
        ReactDOM.render(element, document.getElementById('block-ui-container'));
    }

    static async postData(url: string, params: any) {
        try {
            this.blockUiLoading(true);

            let response = await fetch(apiUrlPrefix + url, {
                method: "POST",
                body: JSON.stringify(params),
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'X-AuthToken': this.getAuthToken()
                }
            })

            //console.log(this.dataPassingHandler(await response));

            return (this.dataPassingHandler(await response));

        } catch (error) {

            this.blockUiLoading(false);

            console.log('error Api get');
            this.clearAuthToken();
            return null;
        }
    }

    static async postUploadFile(url: string, file: any, data: any) {
        this.blockUiLoading(true);
        console.log(data);
        var imgdata = new FormData();
        imgdata.append("file", file);
        imgdata.append("data", JSON.stringify(data));


        try {
            let response = await fetch(apiUrlPrefix + url, {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'X-AuthToken': this.getAuthToken()
                },
                body: imgdata
            })
            let jsonResponse = await response.json();
            this.blockUiLoading(false);
            return jsonResponse;
        } catch (error) {
            this.blockUiLoading(false);
            this.clearAuthToken();
            return null;
        }


    }

    static async postUploadFileClientProfile(url: string, file: any) {
        this.blockUiLoading(true);

        var imgdata = new FormData();
        imgdata.append("file", file);

        try {
            let response = await fetch(apiUrlPrefix + url, {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'X-AuthToken': this.getAuthToken()
                },
                body: imgdata
            });

            let jsonResponse = await response.json();
            this.blockUiLoading(false);
            return jsonResponse;
        } catch (error) {
            this.blockUiLoading(false);
            this.clearAuthToken();
            return null;
        }
    }

    static async putData(url: string, body: FormData) {
        this.blockUiLoading(true);
        try {
            let response = await fetch(apiUrlPrefix + url, {
                method: "PUT",
                body: JSON.stringify(body),
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'X-AuthToken': this.getAuthToken()
                }
            })
            let jsonResponse = await response.json();
            this.blockUiLoading(false);
            return jsonResponse;
        } catch (error) {
            this.blockUiLoading(false);
            this.clearAuthToken();
            return null;
        }
    }

    static async deleteData(url: string) {
        this.blockUiLoading(true);
        try {
            let response = await fetch(apiUrlPrefix + url, {
                method: "DELETE",
                /// body: JSON.stringify(url),
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'X-AuthToken': this.getAuthToken()
                }
            })
            let jsonResponse = await response.json();
            this.blockUiLoading(false);
            return jsonResponse;
        } catch (error) {
            this.blockUiLoading(false);
            this.clearAuthToken();
            return null;
        }
    }


    static weekNumber(dt: any) {
        const tdt: any = new Date(dt);
        const dayn = (dt.getDay() + 6) % 7;
        tdt.setDate(tdt.getDate() - dayn + 3);
        const firstThursday = tdt.getTime();
        tdt.setMonth(0, 1);
        if (tdt.getDay() !== 4) {
            tdt.setMonth(0, 1 + ((4 - tdt.getDay()) + 7) % 7);
        }
        return 1 + Math.ceil((firstThursday - tdt) / 604800000);
    }

    static lookUpTypeList(lookUpType: string, itemName: any, itemReference: string) {

        //console.log(lookUpType + itemName + itemReference)
        // return '';

        /*
        if (lookUpType && itemReference) {
            var temp = global.tagColor.filter((lookUpListItem: any) => lookUpListItem.lookUpType == lookUpType && lookUpListItem.itemName == itemName);
        } else {
            var temp = global.tagColor;
        }
        //console.log(temp)
        if (itemReference == 'fontColor' && temp && temp.length > 0) {
            return (temp[0].fontColor)?.toString();

        } else if (itemReference == 'bgColor' && temp && temp.length > 0) {
            return (temp[0].bgColor)?.toString();
        } else {
            return '';
        }*/

            
        

        if (lookUpType && itemName) {
            var temp = global.tagColor?.filter((lookUpListItem: any) => lookUpListItem.lookUpType == lookUpType && lookUpListItem.itemName == itemName);
        } else if (lookUpType) {
            var temp = global.tagColor?.filter((lookUpListItem: any) => lookUpListItem.lookUpType == lookUpType);
            return temp;
        } else {
            var temp = global.tagColor?.filter((lookUpListItem: any) => lookUpListItem.lookUpType == "General");
        }

        if(temp && temp.length == 0){
            var temp = global.tagColor?.filter((lookUpListItem: any) => lookUpListItem.lookUpType == "General");
        }

        if (itemReference == 'fontColor' && temp && temp.length > 0) {
            return (temp[0].fontColor)?.toString();

        } else if (itemReference == 'bgColor' && temp && temp.length > 0) {
            return (temp[0].bgColor)?.toString();
        } else {
            return '';
        }

    }

    static lookUpTypeListByKey(lookUpType: string, key: string, itemReference: string) {

        if (lookUpType && itemReference) {
            var temp = global?.lookUpTypeList?.filter((lookUpListItem: any) => lookUpListItem.lookUpType == lookUpType && lookUpListItem.key == key);
        } else {
            var temp = global?.lookUpTypeList;
        }
        //console.log(temp)
        if (itemReference == 'value' && temp && temp.length > 0) {
            return (temp[0].value)?.toString();

        } else {
            return '';
        }

    }

    static iconUrlAssignment(subEntityName: string) {
        const landingPage = [
            {
                subEntityName: 'Home',
                subEntityURL: '../../'
            }, {
                subEntityName: 'file2',
                subEntityURL: '../../apps/index.html'
            }, {
                subEntityName: 'pie-chart',
                subEntityURL: '../../apps/contracts/#/summary/'
            }, {
                subEntityName: 'Management',
                subEntityURL: '../../apps/dataMGT/#/itunesMonthly'
            }, {
                subEntityName: 'Matching',
                subEntityURL: '../../apps/dataMGT/#/matching'
            }, {
                subEntityName: 'Management',
                subEntityURL: '../../apps/dataMGT/#/matching'
            }, {
                subEntityName: 'Analytics',
                subEntityURL: '../../apps/affiliates/'
            }, {
                subEntityName: 'Custom Splice',
                subEntityURL: '../../apps/affiliate/'
            }, {
                subEntityName: 'TrackSplit',
                subEntityURL: '../../apps/metadata/#/trackSplit'
            }, {
                subEntityName: 'Portal_TrackSplit',
                subEntityURL: '../../apps/metadata/#/trackSplit'
            }, {
                subEntityName: 'Transaction',
                subEntityURL: '../../apps/dataMGT/#/salesAnalysis'
            }, {
                subEntityName: 'contacts',
                subEntityURL: '../../apps/userManagement/#/register'
            }, {
                subEntityName: 'Licensing',
                subEntityURL: '../../apps/licensing/'
            }, {
                subEntityName: 'Payments',
                subEntityURL: '../../apps/transaction/#/suggestedPayment'
            }, {
                subEntityName: 'stats',
                subEntityURL: '../../apps/transaction/#/quarterRun'
            }, {
                subEntityName: 'calendar5',
                subEntityURL: '../../apps/systemSettings/#/periodMGT'
            }, {
                subEntityName: 'settings1',
                subEntityURL: '../../apps/systemSettings/#/controlPanel'
            }, {
                subEntityName: 'user2',
                subEntityURL: '../../apps/systemSettings/#/clientProfile'
            }, {
                subEntityName: 'line-chart',
                subEntityURL: '../../apps/systemSettings/#/auditTrail'
            }, {
                subEntityName: 'Contracts',
                subEntityURL: '../../apps/contracts/'
            }, {
                subEntityName: 'Contacts',
                subEntityURL: '../../apps/contacts/'
            }, {
                subEntityName: 'Grid_Approval',
                subEntityURL: '../../apps/statements/'
            }, {
                subEntityName: 'Reports',
                subEntityURL: '../../apps/reportings/'
            }, {
                subEntityName: 'Project',
                subEntityURL: '../../apps/licensingPortal/#/projectGrid'
            }, {
                subEntityName: 'Pitch',
                subEntityURL: '../../apps/licensingPortal/#/pitchGrid'
            }, {
                subEntityName: 'tasks',
                subEntityURL: '../../apps/licensingPortal/#/bulkGrid'
            }, {
                subEntityName: 'Uploader',
                subEntityURL: '../../apps/dataLoader/#/metaData'
            }, {
                subEntityName: 'stack2',
                subEntityURL: '../../apps/systemListings/#/'
            }, {
                subEntityName: 'Publishing_Project',
                subEntityURL: '../../apps/contracts/'
            }, {
                subEntityName: 'Synch_Project',
                subEntityURL: '../../apps/licensingPortal/#/projectGrid?Synch'
            }, {
                subEntityName: 'Mechanical_Project',
                subEntityURL: '../../apps/contracts/'
            }, {
                subEntityName: 'Mechanicals',
                subEntityURL: '../../apps/transaction/#/quarterRun'
            }, {
                subEntityName: 'Transaction',
                subEntityURL: '../../apps/dataMGT/#/salesAnalysis/digital'
            }, {
                subEntityName: 'Publishing_Transaction',
                subEntityURL: '../../apps/dataMGT/#/salesAnalysis/publishingSong'
            }, {
                subEntityName: 'Other_Transaction',
                subEntityURL: '../../apps/dataMGT/#/salesAnalysis/costs'
            }, {
                subEntityName: 'Track',
                subEntityURL: '../../apps/metadata/#/tracks'
            }, {
                subEntityName: 'Release',
                subEntityURL: '../../apps/metadata/#/'
            }, {
                subEntityName: 'Song',
                subEntityURL: '../../apps/metadata/#/songs'
            }, {
                subEntityName: 'Assets',
                subEntityURL: '../../apps/metadata/#/assets'
            }, {
                subEntityName: 'SongRegistration',
                subEntityURL: '../../apps/metadata/#/songRegistration?type=songBasket'
            }, {
                subEntityName: 'Projects',
                subEntityURL: '../../apps/contracts/#/projectContracts'
            }, {
                subEntityName: 'Recorded_Music',
                subEntityURL: '../../apps/contracts/'
            }, {
                subEntityName: 'Directories',
                subEntityURL: '../../apps/directories/#/creators'
            }

        ];

        //console.log( subEntityName)
/**/
        for (var i = 0; i < landingPage.length; i++) {
            if (landingPage[i].subEntityName == subEntityName) {
                return landingPage[i].subEntityURL;
            }
        }
    }

    static portalSelection(portalSelection: any) {
        //console.log(portalSelection);
        if (portalSelection.subEntity == "Scraping Tool") {
            return (
                <ScrapingTool subEntity={portalSelection} />
            )
        }
        else if (portalSelection.subEntity == "Directories" ) {
            return (
                <DirectoriesIndex subEntity={portalSelection}/>
            )
        }

        else if (portalSelection.subEntity == "TrackSplit") {
            return (
                <ScrapingTool subEntity={portalSelection} />
            )
        }

        else if (portalSelection.subEntity == "Uploader") {
            return (
                <DataLoader subEntity={portalSelection} />
            )
        }

        else if (portalSelection.subEntity == "Mechanicals") {
            return (
                <QuarterRun subEntity={portalSelection} />
            )
        }

        console.log(portalSelection.subEntity)

        return false;
    }


    static longCharacter(character: any, length: any) {
        var newCharacter = '';
        //console.log(character.toString())
        if ((character) && (character.toString().length > length)) {
            newCharacter = character.toString().slice(0, length) + '...';
        }

        if ((character) && (character.toString().length <= length)) {
            newCharacter = character.toString();
        }

        return newCharacter;
    }
    static stringToArray(stringToArray: string, delimeter: string) {
        if (stringToArray) {
            return stringToArray.split(delimeter);
        } else {
            return [];
        }
    }

    static numberFormat(number: any, decimalPlace: number) {
        if (!number) {
            number = 0;
        }

        number = (Math.round(Number(number) * 100) / 100).toFixed(decimalPlace);

        if (number == 0.00) {
            return number;
        } else {
            number = (Math.round(Number(Math.abs(number)) * 100) / 100).toFixed(decimalPlace);
            return number;
        }

    }

    static setAvatar(avatar: string) {
        console.log(avatar);
        var authToken = JSON.parse(window.sessionStorage.getItem('loggedUserRight') as string);
        console.log(authToken);
        authToken.avatar = avatar;
        window.sessionStorage.setItem('loggedUserRight', JSON.stringify(authToken));
    }

    static setDisplayName(firstName: string, lastName: string) {
        var authTokenn = JSON.parse(window.sessionStorage.getItem('loggedUserRight') as string);
        authTokenn.firstName = firstName;
        authTokenn.lastName = lastName;
        window.sessionStorage.setItem('loggedUserRight', JSON.stringify(authTokenn));
    }

    static async downloadFile(fileURL: string, filename: string) {

        fetch('https://cors-anywhere.herokuapp.com/' + fileURL, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/pdf',
            },
        })
            .then((response) => response.blob())
            .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    `FileName.pdf`,
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                //link.parentNode.removeChild(link);
            });
    }

    static relativeDataRemapping(data: any, dataHeaders: any) {
        var sep_array = [];
        var mix_array = [];
        var subCounter = 0;
        var setTo_Data = {};

        var HeaderName: any[] = [];
        var row_data: { [s: string]: any; } = [];
        var in_data: any[] = [];

        // Object.entries(dataHeaders).forEach(([key, value]) => (console.log(key , value)) ) ;
        Object.entries(dataHeaders).forEach(([key, value]: any) => { HeaderName.push(value.replace(/ /g, '')) });

        //Object.entries(data).forEach(([key, value]) => (console.log(key , value)) ) ;
        Object.entries(data).forEach(([key, value]) => { row_data.push(value) });


        for (var i = 0; i < row_data.length; i++) {

            //Object.entries(FULLdata[i]).forEach(([key, value]) => (console.log(key , value)) ) ;
            Object.entries(row_data[i]).forEach(([key, value]) => { in_data.push(value) });
        }

        for (var count = 0; count < in_data.length; count++) {

            setTo_Data = Object.assign({ [HeaderName[subCounter]]: in_data[count] });
            subCounter++;

            if (subCounter == HeaderName.length) {
                subCounter = 0;
            }
            sep_array.push(setTo_Data);
        }

        for (var D_length = 0; D_length < sep_array.length; D_length++) {
            let array_Temp = {};

            for (var H_length = 0; H_length < HeaderName.length; H_length++) {
                Object.assign(array_Temp, sep_array[D_length + H_length]);
            }

            mix_array.push(array_Temp);
            D_length += HeaderName.length - 1;
        }

        return mix_array;
    }

    static buildQueryString(params: any, preserveParams: boolean, allowEmpty: boolean) {
        const queryParams: string[] = [];

        for (const k in params) {
            if (params.hasOwnProperty(k) && !isNullOrEmpty(params[k])) {
                queryParams.push(
                    preserveParams
                        ? `${k}=${params[k]}`
                        : `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`
                );
            }

            else if (allowEmpty) {
                queryParams.push(`${encodeURIComponent(k)}=`);
            }

        }
        return queryParams.join('&');

    }

    /*static exportWithToken = function (this:any, verb:any , url:any, data:any, target:any, filename:any)
        {
            //blockUI.start();//blockUI.start();

            var XHR = new XMLHttpRequest();

            // We setup our request
            XHR.open(verb, url);

            XHR.responseType = "arraybuffer";

            XHR.onload = function () {
                if (this.status === 200) {
                    var blob = new Blob([XHR.response], {type: "application/zip"});
                    //var objectUrl = URL.createObjectURL(blob);
                    const objectUrl = window.URL.createObjectURL(blob);

                    var a = document.createElement("a");
                    document.body.appendChild(a);
                    a.href = objectUrl;
                    a.download = filename;
                    a.click();
                    document.body.removeChild(a);

                    //IE
                   // blockUI.stop();
                   if (navigator.msSaveBlob) {
                    navigator.msSaveBlob(blob, filename);
                    window.URL.revokeObjectURL(url);
                    //blockUI.stop();
                    //blockUI.start();
                    //$rootScope.$digest();

                   }
                }
               // blockUI.stop();
            };

            // We add the required HTTP header to handle a form data POST request
            XHR.setRequestHeader('Content-Type', 'application/json');
            XHR.setRequestHeader('X-AuthToken', this.getAuthToken());

            // And finally, We send our data.
            XHR.send(JSON.stringify(data));

            
    }*/

    static exportWithToken = async function (this: any, verb: any, url: any, data: any, target: any, filename: any) {

        let response = fetch(apiUrlPrefix + url, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'X-AuthToken': this.getAuthToken()
            }
        })

        const blob = await (await response).blob();

        const objectUrl = URL.createObjectURL(blob);
        //console.log(objectUrl)

        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = objectUrl;
        a.download = filename + '.zip';
        a.click();

        URL.revokeObjectURL(objectUrl);
        /* const zipContent = '';
         const zipArray = new TextEncoder().encode(zipContent);

        
        const zipBlob = new Blob([zipArray], { type: 'application/zip' });
        const newobjectUrl = URL.createObjectURL(zipBlob);
       const b = document.createElement('a');
       b.style.display = 'none';
       b.href = newobjectUrl;
       b.download = 'data.zip';
       b.click();
   
       URL.revokeObjectURL(newobjectUrl);*/


    };




    static download = async (urlLink : any, dataSend :any) => {
        let url = 'YOUR_API_ENDPOINT'; 
        const filename = 'example.zip'; 
        let data = {}; 

        url = urlLink; 
        data = dataSend;
        // Create a fetch request to download the ZIP file
        fetch(apiUrlPrefix + url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/zip',
                'X-AuthToken': this.getAuthToken()
            },
            body: JSON.stringify(data) 
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.blob(); 
            })
            .then(blob => {
                // Create a temporary URL for the blob
                const objectUrl = URL.createObjectURL(blob);

                // Create a temporary anchor element to trigger download
                const a = document.createElement('a');
                a.href = objectUrl;
                a.download = filename;
                document.body.appendChild(a);
                a.click();

                // Clean up
                URL.revokeObjectURL(objectUrl);
            })
            .catch(error => {
                console.error('There was a problem with the fetch operation:', error);
            });
    };


    static postExport = async (url : any , payload :any , fileName :any) => {
        //console.log( url )
        //console.log( payload )

        try {
            const response = await fetch(apiUrlPrefix + url, {
              method: 'POST', 
              headers: {'Content-Type': 'application/json', 'X-AuthToken': this.getAuthToken()},
              body: JSON.stringify(payload),
            });
      
            if (response.ok) {
              const blob = await response.blob(); 
              //console.log( blob )

              const url = window.URL.createObjectURL(new Blob([blob]));
              //console.log( url )

              var file_Name = fileName?fileName +'.zip':'Export' +'.zip'
              //console.log( file_Name )

              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', file_Name);
              document.body.appendChild(link);
              link.click();
        

            } else {
                console.log('Response body:', await response.text());
                alert('Failed to download file NOT OK');
            }

          } catch (error) {
            console.error('Error downloading file:', error);
            alert('Error downloading file');
          }
    };

    static dateFilter(date: any) {

        //new Date()
        //console.log(date);
        //var dates = new Date(date.toISOString());

        if (`${date}`.length == 8) {
            // console.log(format(new Date(`${date}`.substring(4, 6)) , "MMM" ))
            return `${date}`.substring(0, 4) + format(new Date(`${date}`.substring(4, 6)), "MM") + `${date}`.substring(6, 8)

        } else if (`${date}`.length == 19) {
            //  console.log(date);
            return `${date}`.substring(0, 4) + format(new Date(`${date}`.substring(5, 7)), "MM") + `${date}`.substring(8, 10)
        }
        /* if(date == null){
            console.log(new Date('2010-01-31T00:00:00'), "dd MMM yyyy")
            return format(new Date('2010-01-31T00:00:00'), "dd MMM yyyy");
        }else{
            console.log(date)
            console.log( format((date), 'yyyyMMdd') )
            console.log(new Date('20190930'), "dd MMM yyyy")
            // console.log ((new Date(date), "dd MMM yyyy"))
            return format(new Date(date), "dd MMM yyyy");
        }*/
        /*
        
                var dateReturn = new Date();
                if(!date){
                    dateReturn = date;
                //var dates = new Date(date.toString());
                                        //2022-09-30T00:00:00
                }
                return format(dateReturn, "dd MMM yyyy");
                */
    }

    static dateNameFilter(date: any) {

        const monthNames = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
        console.log(monthNames[new Date(date).getMonth()])
        console.log(format(new Date(date), 'm'))
        console.log(format(new Date(date), 'yyyy'))
        console.log(format(new Date(date), 'd'))

        if(date)
            return monthNames[new Date(date).getMonth()].substring(0, 3) +" " + format(new Date(date), 'd') +', ' +format(new Date(date), 'yyyy')
    }


    static exportVsWindow(data: any) {
        window.open(apiUrlPrefix + data);
    }

    static generateChartData(percentageData: any, labels: any, backgroundColor: any, borderColor: any, chartLabel: string, legend: boolean) {



        const options = {
            layout: {
                padding: {
                    top: 0
                }
            },
            plugins: {
                legend: {
                    display: legend,
                    position: "right" as const
                },
                elements: {
                    bar: {
                        borderWidth: 0,
                    },
                }
            }
        };

        const data = {
            labels: labels,
            datasets: [
                {
                    label: chartLabel,
                    data: percentageData,
                    backgroundColor: backgroundColor,
                    borderColor: borderColor[3],
                    borderWidth: 1
                },
            ]
        };

        var chartPassedData = {
            data: data,
            options: options
        }

        console.log(chartPassedData);

        return chartPassedData;
    }
    static myArray = [ "#EF534F",
        "#C62928",
        "#980A0B",
        "#BA69C8",
        "#7B1FA2",
        "#4A148C",
        "#FFD54F",
        "#F57C02",
        "#E65100",
        "#81C784",
        "#2F7D31",
        "#1A5E20",
        "#7FDEEA",
        "#00838F",
        "#004D41",
        "#90CAF9",
        "#029BE5",
        "#01579B",
        "#FFFFFF",
        "#DBDBDB",
        "#000000"
    ]; 
    /*static colorPallet() {

        
        return myArray;
        return [ "#EF534F",
            "#C62928",
            "#980A0B",
            "#BA69C8",
            "#7B1FA2",
            "#4A148C",
            "#FFD54F",
            "#F57C02",
            "#E65100",
            "#81C784",
            "#2F7D31",
            "#1A5E20",
            "#7FDEEA",
            "#00838F",
            "#004D41",
            "#90CAF9",
            "#029BE5",
            "#01579B",
            "#FFFFFF",
            "#DBDBDB",
            "#000000"
        ]
    }
*/
}

function isNullOrEmpty(value: string) {
    return value === undefined || value === null || value === '';
}