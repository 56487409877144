import CommonSearch from "../../components/commonSearch/CommonSearch";
import globalSearchService from "../../components/commonSearch/globalSearchServices";
import React, { Suspense, useState, useEffect } from "react";
import directoryServices from "./directoryServices";
import AddImprint from "./Add Slider/addImprint";
import DirectoryAnalytic from "./Analytic/DirectoryAnalytic";
import Pagination from "../Header/Pagination";
import DeleteDialogueBox from "../Common/deleteDialogue";

export default function Imprint(param: any) {


 //    console.log(param)
    useEffect(() => {
        globalSearchService.lookUpTypeList()
            .then((res) => {
                global.lookUpTypeList = res;
            });
    }, []);

    let [activeMenu, setActiveMenu] = useState("Imprints");
    let [activeCategory, setActiveCategory] = useState("All");
    let [totalRow, settotalRow] = useState(0);
    let [SubtotalRow, setSubtotalRow] = useState(0);
    let [directoryFullListing, setdirectoryFullListing] = useState<any[]>([]);
    let [directoryViewAll, setdirectoryViewAll] = useState<any[]>([]);
    let [viewAllList, setviewAllList] = useState(false);
    let [categoryList, setCategoryList] = useState<any[]>([]);
    const [perCategory, setperCategory] = useState(false);
    let [selectedDetail, setselectedDetail] = useState<any>([]);

    const [detailView, setdetailView] = useState(false);
    const [detailMainTab, setdetailMainTab] = useState(1);
    let [detailSubTab, setdetailSubTab] = useState(1);
    let [activedetailCategory, setActivedetailCategory] = useState("All");

    var loop = 0;
    var pagination = {
        'pageSize': 10,
        'pageNumber': 1,
        "display": "Category",
        "type": "Imprints"
    }

    useEffect(() => {
        setActiveCategory("All");
        if (loop == 0) {
            LandingListing();
            console.log(loop)
            loop = loop + 1;
        }

    }, [param])

    const LandingListing = () => {
        directoryServices.labelSearchListing({ ...pagination }).then((res) => {
            // console.log(res)   

            res.map((values: any) => {

                if (values.categoryName != ' ' && (categoryList.length < res.length - 1)) {
                    categoryList.push(values.categoryName)
                    setCategoryList(categoryList)
                }
            })
            setdirectoryFullListing(res)
            settotalRow(res[0] ? res[0].totalRows : 0)

        })
        setperCategory(false);
    }

    const SearchForHandle = (e: any, sts: any) => {
        console.log(e)
        console.log(sts)

        if ((sts == true)) {

            directoryServices.labelSearchListing({ ...e, ...pagination }).then((res) => {
                console.log(res)


                res.map((values: any) => {

                    if (values.categoryName != ' ' && (categoryList.length < res.length - 1)) {
                        categoryList.push(values.categoryName)
                        setCategoryList(categoryList)
                    }
                })
                setdirectoryFullListing(res)
                settotalRow(res[0] ? res[0].totalRows : 0)

            }) /*  */
        }
        setperCategory(false);
    }

    const viewAll = (e: any) => {
        // console.log(e)
        if (viewAllList == false) {
            directoryServices.labelSearchListingAll({ ...{ "categoryName": e.categoryName }, ...pagination }).then((res) => {
                setdirectoryViewAll(res)
                //setSubtotalRow (res[0]?res[0].totalRows : 0)
            })
        }
    }

    const viewCategory = (e: any) => {
        console.log(e)

        // setperCategory(true)
        directoryServices.labelSearchListingAll({ ...{ "categoryName": e }, ...pagination }).then((res) => {
            setdirectoryFullListing(res)
            settotalRow(res[0] ? res[0].totalRows : 0)

        })


    }

    var creatorAssetPayLoad = {
        assetType: "T",
        categoryName: "",
        AssetImprint: selectedDetail.companyName,
        display: "Category",
        pageNumber: 1,
        pageSize: 10,
    }

    var bundlePayLoad = {
        pageNumber: 1,
        pageSize: 10,
        bundleImprint: selectedDetail.companyName
    }

    let [creatorAssetList, setcreatorAssetList] = useState<any>([]);
    let [creatorAssetCategory, setcreatorAssetCategory] = useState<any>([]);
    let [creatorAssettotalRow, setcreatorAssettotalRow] = useState(0);

    var SubclassFicationPayload = {
        area: "Imprints",
        referenceCode: "",
        text: "",
        pageNumber: 1,
        pagesize: 10
    }

    let [associatedSubclassification, setassociatedSubclassification] = useState<any>([]);
    let [associatedclassification, setassociatedclassification] = useState<any>([]);
    const creatorAsset = (e: any) => {

        if (e != "All") {
            creatorAssetPayLoad.categoryName = e;
        } else { creatorAssetPayLoad.categoryName = ""; }

        //console.log(detailSubTab)
        if (detailSubTab == 1) {
            creatorAssetPayLoad.assetType = 'T';
        } else if (detailSubTab == 2) {
            creatorAssetPayLoad.assetType = "S";
        } else if (detailSubTab == 3) { creatorAssetPayLoad.assetType = "R"; }

        console.log(selectedDetail)
        setselectedDetail(selectedDetail)

        creatorAssetPayLoad.AssetImprint = selectedDetail.companyName;
        bundlePayLoad.bundleImprint = selectedDetail.companyName;
        relatedAssetPayLoad.CreatorCode = selectedDetail.companyName;

        if (detailSubTab < 4) {
            directoryServices.MainAssetListing(creatorAssetPayLoad).then((res) => {
                setcreatorAssetList(res)
                setcreatorAssettotalRow(res[0] ? res[0].totalRows : 0)
                for (var $i = 0; $i < res.length; $i++) {

                    if (res[$i].categoryName != ' ' && e == "All" && (creatorAssetCategory.length < res[$i].length - 1)) {
                        //console.log("enter " + res[$i].categoryName)
                        creatorAssetCategory.push(res[$i].categoryName)
                        setcreatorAssetCategory(creatorAssetCategory)
                        //console.log(creatorAssetCategory)
                    }
                }
            })
        } else if (detailSubTab == 4) {
            directoryServices.BundleAssetListing(bundlePayLoad).then((res) => {
                setcreatorAssetList(res)
                //console.log(res)
                setcreatorAssettotalRow(res[0] ? res[0].totalRows : 0)
            })
        }

        SubclassFicationPayload.referenceCode = selectedDetail.companyName;
        /*directoryServices.tagListing(SubclassFicationPayload).then((res) => {
            setassociatedSubclassification(res)
        })

        directoryServices.musicDirectoryCategoryListing({ code: selectedDetail.companyName, CategoryType: "Imprints" }).then((res) => {
            setassociatedclassification(res)
        })*/

    }

    useEffect(() => {
        if (activedetailCategory == "All") {
            creatorAssetCategory = []
            setcreatorAssetCategory(creatorAssetCategory)
        }

        //console.log(creatorAssetList)
        for (var $i = 0; $i < creatorAssetList.length; $i++) {
            //console.log("enter " + creatorAssetCategory.length)
            //console.log("enter " + creatorAssetList.length)
            if (creatorAssetList[$i].categoryName != ' ' && activedetailCategory == "All" && (creatorAssetCategory.length <= creatorAssetList.length)) {
                //console.log("enter " + creatorAssetList[$i].categoryName)
                creatorAssetCategory.push(creatorAssetList[$i].categoryName)
                setcreatorAssetCategory(creatorAssetCategory)
                //console.log(creatorAssetCategory)
            }
        }

    }, [creatorAssetList])


    const set_detailSubTab = (e: any) => {
        detailSubTab = e
        setActivedetailCategory('All')
        setdetailSubTab(detailSubTab)
    }

    var relatedAssetPayLoad = {
        CreatorCode: selectedDetail.companyName,
        type: "Creators",
        pageNumber: 1,
        pageSize: 10
    }

    let [creatorRelatedArtist, setcreatorRelatedArtist] = useState<any>([]);
    const relatedArtistListing = () => {
        directoryServices.creatorsListingAll(relatedAssetPayLoad).then((res) => {
            setcreatorRelatedArtist(res)
            console.log(res)
            setcreatorAssettotalRow(res[0] ? res[0].totalRows : 0)
        })
    }

    let [dialogueOpen, setdialogue] = useState(0)

    let [dialogueAddOpenView, setdialogueAddOpenView] = useState(false)
    const onAddClose = (e: any) => {
        console.log("")
        setdialogueAddOpenView(false)
    }

    const onChangeMenu = (e: any) => {
        setActiveMenu(e)
        param.onChangeMenu(e)
    }


    const handleNameOnChange = (n: any) => {
        creatorAsset("All")
        if (detailSubTab < 4) {
            creatorAssetPayLoad.pageNumber = n.pageNumber;
            creatorAssetPayLoad.pageSize = n.pageSize;

        } else if (detailSubTab == 4) {
            bundlePayLoad.pageNumber = n.pageNumber;
            bundlePayLoad.pageSize = n.pageSize;
        }

    }

    const [deleteJob, setDelete] = useState(false);
    const Confirmation = (e: any) => {

        console.log(selectedDetail)
        if (e == 1) {
            directoryServices.deleteImprint(selectedDetail.labelID)
            setdetailView(false)
            setActiveCategory("All");
            LandingListing();
        }
        setDelete(false);
    }

    return (

        <div className="landing ws-12">

            <div className="ws-12">

                <div className="box-header-main">
                    <h2>Music Directories</h2>
                </div>

                <div className="ws-2 box mtxl pm">
                    <ul className="main-menu">
                        <a className="menu-header">Music Directories</a>
                        <ul>
                            {
                                directoryServices.directoryMenu?.map((value: any) => {
                                    return (
                                        <>
                                            {(value.route != 'accounts') && <li key={value.title} className={activeMenu == value.title ? "active" : ""}>
                                                <a className={"ws-12 "} href={"#/" + value.route} onClick={() => { onChangeMenu(value.title) }}> {value.title} </a>
                                            </li>}
                                        </>
                                    )
                                })
                            }
                        </ul>
                        <a className="menu-header">Data Directories</a>

                        <ul>
                            {
                                directoryServices.directoryMenu?.map((value: any) => {
                                    return (
                                        <>
                                            {(value.route == 'accounts') && <li key={value.title} className={activeMenu == value.title ? "pull-left ws-12 active" : "pull-left ws-12 "}>
                                                <a className={"ws-12 "} href={"#/" + value.route} onClick={() => { onChangeMenu(value.title) }}> {value.title} </a>
                                            </li>}
                                        </>
                                    )
                                })
                            }

                        </ul>
                    </ul>

                    <div className="main-menu-span m0 box ws-12 fullHeight hide">
                        <div className="sidebar-nav ws-12">
                            <ul className="nav nav-stacked main-menu m0 ws-12" id="mainMenu">
                                <li key="MusicDirectories" className="mtl ws-12 pull-left p0" style={{ background: "none", border: "none" }}>
                                    <a className="nav-header full-width text-bold text-average "><i className="fa fa-music"></i> Music Directories</a>

                                    <ul className="nav nav-tabs full-width">
                                        {
                                            directoryServices.directoryMenu?.map((value: any) => {
                                                return (
                                                    <>
                                                        {(value.route != 'accounts') && <li key={value.title} className={activeMenu == value.title ? "pull-left ws-12 active" : "pull-left ws-12 "}>
                                                            <a className={"ws-12 "} href={"#" + value.route} onClick={() => onChangeMenu(value.title)}> {value.title} </a>
                                                        </li>}
                                                    </>
                                                )
                                            })
                                        }

                                    </ul>
                                </li>

                                <li key="DataDirectories" className="mtl ws-12 pull-left p0" style={{ background: "none", border: "none" }}>
                                    <a className="nav-header full-width text-bold text-average "><i className="fa fa-music"></i> Data Directories</a>

                                    <ul className="nav nav-tabs full-width">
                                        {
                                            directoryServices.directoryMenu?.map((value: any) => {
                                                return (
                                                    <>
                                                        {(value.route == 'accounts') && <li key={value.title} className={activeMenu == value.title ? "pull-left ws-12 active" : "pull-left ws-12 "}>
                                                            <a className={"ws-12 "} href={"#" + value.route} onClick={() => onChangeMenu(value.title)}> {value.title} </a>
                                                        </li>}
                                                    </>
                                                )
                                            })
                                        }

                                    </ul>
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>

                <div className="ws-10">
                    {(detailView == false) && <><div className="pLarge">

                        <div className="ws-9">
                            <ul className="nav nav-tabs p0 m0 green-nav-tabs">
                                <li key='All' className={activeCategory == 'All' ? "active " : ""}
                                    onClick={() => { setActiveCategory('All'); setperCategory(false); LandingListing(); }}>All</li>
                                {
                                    categoryList?.map((list: any) => {
                                        return (
                                            <li key={list} className={activeCategory == list ? "active " : ""}
                                                onClick={() => { setActiveCategory(list); setperCategory(true); viewCategory(list) }}>{list}</li>
                                        )
                                    })
                                }
                            </ul>
                        </div>

                        <div className="ws-3">
                            <div className="verticalMediumWide hover-box">
                                <ul className="hover-block textboxDropdownList box pLarge defaultColor rightReset ws-2 mr">
                                    <li key='Add Account' className="ws-12 pbs">
                                        <a onClick={() => { setdialogueAddOpenView(true);setselectedDetail({}) }}>Add new Imprint</a>
                                    </li>
                                </ul>
                                <button className="btn btn-info pull-right pt">
                                    <i className="icon-grid m0 p0"></i>
                                </button>
                            </div>

                        </div>

                        <hr />
                        <div className="ws-12 ">
                            <CommonSearch SearchForHandle={SearchForHandle} type="contactGrid" title="Imprints" width="ws-10 pull-left" />
                        </div>

                        <div className="ws-12">
                            <div className="box-content">
                                <h2>Showing <span className="info">{totalRow}</span> results</h2>
                            </div>
                        </div>

                    </div>

                        <div className="ws-12 landing pLarge">

                            {(!perCategory && directoryFullListing.length > 0) && <>
                                {
                                    directoryFullListing?.map((valueObj: any) => {
                                        return (
                                            <>
                                                {(valueObj && valueObj.categoryName != " ") && <h2 className="ws-12">
                                                    <span className="text-bold pt">{valueObj.categoryName}</span>
                                                    <span className="info">({valueObj.categoryDetails ? valueObj.categoryDetails[0].totalSubRows : 0})</span>
                                                    {((valueObj.categoryDetails && valueObj.categoryDetails[0].totalSubRows) > 5) && <a className="btn-primary pull-right" onClick={() => { viewAll(valueObj); setviewAllList(!viewAllList) }}> View All </a>}
                                                </h2>}

                                                {(valueObj && valueObj.categoryName != " ") && <div>
                                                    {
                                                        valueObj.categoryDetails?.map((value: any, index: any) => {

                                                            if (!(directoryViewAll[0] && valueObj.categoryName == directoryViewAll[0].categoryName && viewAllList)) {
                                                                return (

                                                                    <>{(index < 5) && <div className="creatorImg pm pull-left">

                                                                        <div className="box shadowCoverPicture shadow miniCreatorImg" onClick={() => { setdetailView(true); selectedDetail = (value); creatorAsset("All") }}>

                                                                            <div className="coverPicture">

                                                                                <img className="ws-12" src={value.labelImage} />

                                                                            </div>
                                                                            <div className="bottomOverlayLabel">
                                                                                <label className="control-label ws-12"> {value.companyName}</label>
                                                                                <label className="control-label ws-12 hide"> {value.companyName} </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>}</>

                                                                )
                                                            }

                                                            if ((directoryViewAll[0] && valueObj.categoryName == directoryViewAll[0].categoryName && index < 1 && viewAllList)) {

                                                                return (<>
                                                                    {directoryViewAll.map((values) => {
                                                                        return (
                                                                            <div className="creatorImg pm pull-left">

                                                                                <div className="box shadowCoverPicture shadow miniCreatorImg" onClick={() => { setdetailView(true); selectedDetail = (value); creatorAsset("All") }}>

                                                                                    <div className="coverPicture">

                                                                                        <img className="ws-12" src={values.labelImage} />

                                                                                    </div>
                                                                                    <div className="bottomOverlayLabel">
                                                                                        <label className="control-label ws-12"> {values.companyName}</label>
                                                                                        <label className="control-label ws-12"> {values.companyName} </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>)
                                                                    })}</>)
                                                            }
                                                        })
                                                    }
                                                </div>}
                                            </>
                                        )
                                    })
                                }

                                {
                                    directoryFullListing?.map((valueObj: any) => {
                                        return (
                                            <>
                                                {(valueObj.categoryName == " ") && <h2 className="ws-12">
                                                    <span className="text-bold pt"> Uncategorised</span>
                                                    <span className="info">({valueObj.categoryDetails ? valueObj.categoryDetails[0].totalSubRows : 0})</span>
                                                    {((valueObj.categoryDetails && valueObj.categoryDetails[0].totalSubRows) > 5) && <a className="btn-primary pull-right" onClick={() => { viewAll(valueObj); setviewAllList(!viewAllList) }}> View All </a>}
                                                </h2>}

                                                {(valueObj.categoryName == " ") && <div >
                                                    {
                                                        valueObj.categoryDetails.map((value: any, index: any) => {
                                                            if (!(directoryViewAll[0] && valueObj.categoryName == directoryViewAll[0].categoryName && viewAllList)) {
                                                                return (

                                                                    <>{(index < 5) && <div className="creatorImg pm pull-left">

                                                                        <div className="box shadowCoverPicture shadow miniCreatorImg" onClick={() => { setdetailView(true); selectedDetail = (value); creatorAsset("All") }}>

                                                                            <div className="coverPicture">

                                                                                <img className="ws-12" src={value.labelImage} />

                                                                            </div>
                                                                            <div className="bottomOverlayLabel">
                                                                                <label className="control-label ws-12"> {value.companyName}</label>
                                                                                <label className="control-label ws-12"> {value.companyName} </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>}</>

                                                                )
                                                            }

                                                            if ((directoryViewAll[0] && valueObj.categoryName == directoryViewAll[0].categoryName && index < 1 && viewAllList)) {

                                                                return (<>
                                                                    {directoryViewAll.map((values) => {
                                                                        return (
                                                                            <div className="creatorImg pm pull-left">

                                                                                <div className="box shadowCoverPicture shadow miniCreatorImg" onClick={() => { setdetailView(true); selectedDetail = (value); creatorAsset("All") }}>

                                                                                    <div className="coverPicture">

                                                                                        <img className="ws-12" src={values.labelImage} />

                                                                                    </div>
                                                                                    <div className="bottomOverlayLabel">
                                                                                        <label className="control-label ws-12"> {values.companyName}</label>
                                                                                        <label className="control-label ws-12"> {values.companyName} </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>)
                                                                    })}</>)
                                                            }

                                                        })
                                                    }
                                                </div>}
                                            </>
                                        )
                                    })
                                }</>}


                            {(perCategory && directoryFullListing.length > 0) && <>

                                {directoryFullListing?.map((values: any) => {
                                    return (
                                        <div className="creatorImg pm pull-left">

                                            <div className="box shadowCoverPicture shadow miniCreatorImg" onClick={() => { setdetailView(true); selectedDetail = (values);; creatorAsset("All") }}>

                                                <div className="coverPicture">

                                                    <img className="ws-12" src={values.labelImage} />

                                                </div>
                                                <div className="bottomOverlayLabel">
                                                    <label className="control-label ws-12"> {values.companyName}</label>
                                                    <label className="control-label ws-12"> {values.companyName} </label>
                                                </div>
                                            </div>
                                        </div>)
                                })

                                }
                            </>}

                        </div></>}

                    {(detailView == true) && <>
                        <div className="ws-12 ">
                            <div className="ws-12 pLarge">
                                <button className="btn-primary btn-sm mrl" onClick={() => setdetailView(false)}>
                                    <i className="icon-angle-left"></i>
                                </button>

                                <div className="mt">
                                    <span className="text-bold pt" style={{ textDecoration: "underline" }}> Imprints directory</span>
                                    <span className="text-bold pt"> {">"} {selectedDetail.companyName}</span></div>
                            </div>

                            <hr className="mtxl" />

                        </div>

                        <div className="ws-12 pLarge">
                            <div className="ws-12">
                                <div className=" box  shadow" style={{ width: "200px", height: "200px" }}>
                                    <div className="coverPicture" style={{ height: "200px" }}>
                                        <img className="ws-12 hide" src={selectedDetail.labelImage} />
                                        <img className="ws-12" src={selectedDetail.labelImage} />
                                    </div>
                                </div>

                                <div className="ws-8 pll ptll">
                                    <div className="ws-12 text-xl text-bold mbs ptl ">{selectedDetail.companyName}</div>

                                    <div className=" ">
                                        {
                                            associatedclassification?.map((values: any) => {
                                                return (
                                                    <span className="btn pull-left btn-black">{values.categoryName}</span>
                                                )

                                            })
                                        }


                                    </div>

                                    <div className=" ">

                                        {
                                            associatedSubclassification?.map((values: any) => {
                                                return (
                                                    <span className="btn pull-left btn-blackBorder">{values.text}</span>
                                                )
                                            })
                                        }
                                    </div>

                                    <span className="ws-12 info text-bold mbl ptl ">{selectedDetail.companyName}</span>
                                </div>

                                <div className="verticalMediumWide hover-box">
                                    <ul className="hover-block textboxDropdownList box pLarge defaultColor rightReset ws-2">

                                        <li className="ws-12 pbs">   <a onClick={() => { setdialogue(2); setdialogueAddOpenView(true) }}>Edit Profile</a> </li>
                                        <li className="ws-12 danger"> <a onClick={() => setDelete(true)} style={{ color: "red" }}>Delete Imprint</a> </li>
                                    </ul>
                                    <button className="btn btn-info pull-right pt">
                                        <i className="icon-grid m0 p0"></i>
                                    </button>
                                </div>

                            </div>

                            <div className="ws-12 ">

                                <ul className="nav nav-tabs ws-12 mbll mtll  ws-12">
                                    <li className={detailMainTab == 1 ? "mrl active" : "mrl"} onClick={() => { setdetailMainTab(1); creatorAsset("All") }}>Assets</li>{/* 
                                        <li className={detailMainTab ==2? "mrl active" : "mrl"} onClick={()=>{setdetailMainTab(2) ;relatedAssetListing()}}>Related Assets</li> */}
                                    <li className={detailMainTab == 3 ? "mrl active" : "mrl"} onClick={() => { setdetailMainTab(3); relatedArtistListing() }}>Related Artists</li>
                                    <li className={detailMainTab == 4 ? "mrl active" : "mrl"} onClick={() => setdetailMainTab(4)}>Analytics</li>
                                    <hr className="ws-12 m0 p0" />
                                </ul>

                            </div>

                            {(detailMainTab == 1) && <div className="forceSingleLine pull-left">

                                <ul className="nav nav-tabs ws-12">

                                    <li className={detailSubTab == 1 ? " plxl prxl active" : " plxl prxl"} onClick={() => { set_detailSubTab(1); creatorAsset('All') }}>Tracks</li>
                                    <li className={detailSubTab == 2 ? " roundButtons plxl prxl active" : " plxl prxl"} onClick={() => { set_detailSubTab(2); creatorAsset('All') }}> Songs </li>
                                    <li className={detailSubTab == 3 ? " plxl prxl active" : " plxl prxl"} onClick={() => { set_detailSubTab(3); creatorAsset('All') }}>Releases</li>
                                    <li className={detailSubTab == 4 ? " plxl prxl active hide" : " plxl prxl hide"} onClick={() => { set_detailSubTab(4); creatorAsset('All') }}>Bundles </li>

                                </ul>
                            </div>}

                        </div>

                        <div className="landing ws-12 plx">
                            {(detailMainTab == 1) && <div className="forceSingleLine pull-left ws-12">
                                <div className="box-content p0 mtl">
                                    <h2>Showing <span className="info">{creatorAssettotalRow}</span> results</h2>

                                    {(detailSubTab < 4 && creatorAssettotalRow > 0) && <div className="ws-9">
                                        <ul className="nav nav-tabs p0 m0">
                                            <li key='All' className={activedetailCategory == 'All' ? "m0 mt mr plxl prxl active" : "m0 mt mr plxl prxl "}
                                                onClick={() => { setActivedetailCategory('All'); creatorAsset('All') }}> All</li>

                                            {
                                                creatorAssetCategory?.map((values: any) => {
                                                    return (
                                                        <li key='All' className={activedetailCategory == values ? "m0 mt mr plxl prxl active" : "m0 mt mr plxl prxl "}
                                                            onClick={() => { setActivedetailCategory(values); creatorAsset(values) }}>
                                                            {values}
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </div>}
                                </div>

                                <div className="ws-12">

                                    {
                                        (() => {
                                            if (detailSubTab < 4) {

                                                return (
                                                    creatorAssetList?.map((value: any) => {
                                                        return (
                                                            value.categoryDetails?.map((values: any) => {
                                                                return (
                                                                    <div className="creatorImg pm pull-left" >

                                                                        <div className="box shadowCoverPicture shadow miniCreatorImg">
                                                                            <div className="coverPicture">
                                                                                <img className="ws-12" src={values.assetImage} />
                                                                            </div>

                                                                            <i className="icon-pictures1 primary pull-none coverPicture ptl hide" ></i>

                                                                            <div className="bottomOverlayLabel">
                                                                                <label className="control-label ws-12">{values.name}</label>
                                                                                <label className="control-label ws-12 text-bold text-uppercase"> {values.assetContactCode}</label>
                                                                            </div>
                                                                        </div>

                                                                    </div>)
                                                            }))
                                                    }))
                                            }

                                            else if (detailSubTab == 4) {
                                                return (
                                                    creatorAssetList?.map((values: any) => {
                                                        return (
                                                            <div className="creatorImg pm pull-left" >

                                                                <div className="box shadowCoverPicture shadow miniCreatorImg">
                                                                    <div className="coverPicture">
                                                                        <img className="ws-12" src={values.assetImage} />
                                                                    </div>

                                                                    <i className="icon-pictures1 primary pull-none coverPicture ptl hide" ></i>

                                                                    <div className="bottomOverlayLabel">
                                                                        <label className="control-label ws-12">{values.bundleTitle}</label>
                                                                        <label className="control-label ws-12 text-bold text-uppercase"> {values.bundleCode}</label>
                                                                    </div>
                                                                </div>

                                                            </div>)
                                                    }))
                                            }
                                        })()
                                    }
                                </div>

                            </div>}

                            {(detailMainTab == 3) && <div className="forceSingleLine pull-left">

                                <h2>Showing <span className="info">{creatorAssettotalRow}</span> results</h2>
                                {creatorRelatedArtist?.map((values: any) => {
                                    return (
                                        <div className="creatorImg pm pull-left" >

                                            <div className="box shadowCoverPicture shadow miniCreatorImg">
                                                <div className="coverPicture">
                                                    <img className="ws-12" src={values.creatorImage} />
                                                </div>

                                                <i className="icon-pictures1 primary pull-none coverPicture ptl hide" ></i>

                                                <div className="bottomOverlayLabel">
                                                    <label className="control-label ws-12">{values.kontactDisplay}</label>
                                                    <label className="control-label ws-12 text-bold text-uppercase"> {values.kontactCode}</label>
                                                </div>
                                            </div>

                                        </div>
                                    )
                                })}
                            </div>}

                            {(detailMainTab == 4) && <div className="forceSingleLine pull-left ws-12">
                                <DirectoryAnalytic />
                            </div>}

                        </div>

                    </>}

                </div>

            </div>

            {
                (() => {

                    if (dialogueAddOpenView) {
                        return (<AddImprint onChangeClose={onAddClose} open={dialogueAddOpenView} selectedDetail={selectedDetail} />)
                    }

                    if (deleteJob) {
                        return <DeleteDialogueBox param={true} message={"delete contact " + selectedDetail.companyName} onChange={Confirmation} />
                    }
                })()
            }
        </div>
    )
}   