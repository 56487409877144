import React, { Suspense, useState, useEffect } from "react";
import { Modal } from 'react-responsive-modal';

import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { HexColorPicker } from "react-colorful";
import { CommonUtilities } from "commonUtils";
import commonServices from "components/Common/commonServices";

export default function ThemeIndex() {

const [openFirst, setOpenFirst] = React.useState(false);

let styleList : any = [] ;

const clickHandlerSlider = (e: any, className : string, attribute : string, extension : string) => {
    //var styles = className;
    console.log(e);

    var temp = styleList.filter((e:any)=> e.className == className &&  e.attribute == attribute);
    console.log(temp);

    if(temp.length > 0){
        styleList.filter((e:any)=> e.className == className &&  e.attribute == attribute)[0].value = e+extension;
    }else{
        styleList.push({id : '', className : className, attribute : attribute, value : e+extension});
    }


    className = '.previewTheme ' + className + '{ ' + attribute + ' : ' + e + extension +' !important;}'
    const styleElement = document.createElement('style');
    styleElement.innerHTML = className;
    document.head.appendChild(styleElement);
    //getContrastYIQ(e);
  
}



const saveTheme = () => {
    console.log(JSON.stringify(styleList));
    commonServices.saveTheme({ classNameList : styleList })
    .then((res) => { })
}



const hexToRgb = (hex: string): string | null => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? `rgb(${hexToInt(result, 1)}, ${hexToInt(result, 2)},${hexToInt(result, 3)})`
      : null;
  };

  const hexToInt = (arr: RegExpExecArray, index: number): number => parseInt(arr[index], 16);

const getContrastYIQ = (hex: string) => {
    const rgb = hexToRgb(hex);
    const modifyRgb: Array<string> = rgb
      ? rgb
          .substring(4, rgb.length - 1)
          .replace(/ /g, '')
          .split(',')
      : [];
    const yiq: number = modifyRgb?.length
      ? (Number(modifyRgb[0]) * 299 + Number(modifyRgb[1]) * 587 + Number(modifyRgb[2]) * 114) / 1000
      : 0;


      console.log(modifyRgb);
      
    //return yiq >= 128 ? BackgroundTypes.Light : BackgroundTypes.Dark;
  };

/*
 const getContrastYIQ = (hex: string) => {
    const modifyRgb = [20, 44, 207]
    const yiq: number = modifyRgb?.length
      ? (Number(modifyRgb[0]) * 299 + Number(modifyRgb[1]) * 587 + Number(modifyRgb[2]) * 114) / 1000
      : 0;

    console.log(yiq);
    
  };
*/

const [color, setColor] = useState("#b32aa9");

    return (
        <div className="pull-left">
            <div className="box-primary text-center iconLogo pull-right" onClick={()=>setOpenFirst(true)}>
            <div className="ws-12">
                <i className="box pm icon-droplet"></i>
                Themes
            </div>
        </div>


            <Modal open={openFirst} onClose={() => setOpenFirst(false)} center>

                <div className="box">
                    <div className="box-header-main">
                        <h2>Theme Customization</h2>
                    </div>
                    <div className='box-content'>






























                        <div className="ws-4">

                            <div className="form-format m0 mbl">
                                <label className="control-label ws-4 text-right prll">Font Size</label>
                                <div className="label-entries ws-4">
                                    <Slider min={0.7} max={1.2} step={0.01}  onChange={(e) => clickHandlerSlider(e, '*', 'font-size', 'rem')}/>
                                </div>
                            </div>

                            <div className="form-format m0 mbl">
                                <label className="control-label ws-4 text-right prll">Rounded Corners</label>
                                <div className="label-entries ws-4">
                                    <Slider min={0} max={10} step={1}  onChange={(e) => clickHandlerSlider(e, '.box', 'border-radius', 'px')}/>
                                </div>
                            </div>

                            

                            <div className="form-format m0 mbl">
                                <label className="control-label ws-4 text-right prll">Rounded Buttons</label>
                                <div className="label-entries ws-4">
                                    <Slider min={0} max={25} step={1}  onChange={(e) => clickHandlerSlider(e, '[class*="btn"], .nav.nav-tabs li, input', 'border-radius', 'px')}/>
                                </div>
                            </div>

                            <div className="form-format m0 mbl">
                                <label className="control-label ws-4 text-right prll">Font Color</label>
                                <div className="label-entries ws-8">
                                    <HexColorPicker color={color} onChange={ (color) => clickHandlerSlider(color, '*', 'color', '') } />
                                </div>
                            </div>

                            
                            <div className="form-format m0 mbl hide">
                                <label className="control-label ws-4 text-right prll">Header Background Color</label>
                                <div className="label-entries ws-8">
                                    <HexColorPicker color={color} onChange={ (color) => clickHandlerSlider(color, '.box-header-main, .box-header', 'background', '') } />
                                </div>
                            </div>

                        </div>

                        <div className="ws-8">
                            <div className="pLarge previewTheme ws-12">
                                <div className="box borderSolid">




                                    <div className="box-header-main">
                                        <div className="hover-box pl">
                                            
                                            <button className="ps mt btn"><i className="icon-droplet"></i></button>
    
                                            <ul className="hover-block landing ws-3 pull-left m0 hide">
                                                <li className="ws-6 ps">
                                                    <div className="box">
                                                        <div className="ws-12 text-center ps text-bold">
                                                            Text
                                                        </div>

                                                        <ul className="ws-12 pm">
                                                            {CommonUtilities.myArray?.map(item => (
                                                            <li className=" pull-left width-auto ps">
                                                                <a className="pLarge btn m0 roundedButtons pull-left" style={{background: item }} onClick={ () => clickHandlerSlider(item, '.box-header-main h2', 'color', '')} >

                                                                </a>
                                                            </li>
                                                            ))}
                                                        </ul>
                                                    </div>

                                                </li>
                                                <li className="ws-6 ps">
                                                    <div className="box">
                                                        <div className="ws-12 text-center ps text-bold">
                                                            Background
                                                        </div>

                                                        <ul className="ws-12 pm">
                                                            {CommonUtilities.myArray?.map(item => (
                                                            <li className=" pull-left width-auto ps">
                                                                <a className="pLarge btn ws-12 m0 roundedButtons" style={{background: item }}  onClick={ () => clickHandlerSlider(item, '.box-header-main', 'background', '') } >

                                                                </a>
                                                            </li>
                                                            ))}
                                                        </ul>

                                                    </div>
                                                    
                                                </li>

                                                
                                            </ul>

                                        </div>

                                        <div className="box-primary">
                                            <h2>
                                                Main Headers
                                            </h2>
                                        </div>
                                    </div>

                                    <div className="box-content">


                                    <div className="hover-box pull-left">
                                            
                                        <button className="ps mtl btn"><i className="icon-droplet"></i></button>

                                        <ul className="hover-block landing ws-3 pull-left m0 hide">
                                            <li className="ws-6 ps">
                                                    <div className="box">
                                                        <div className="ws-12 text-center ps text-bold">
                                                            Text
                                                        </div>

                                                        <ul className="ws-12 pm">
                                                            {CommonUtilities.myArray?.map(item => (
                                                            <li className=" pull-left width-auto ps">
                                                                <a className="pLarge btn ws-12 m0 roundedButtons" style={{background: item }} onClick={ () => { clickHandlerSlider(item, '.nav li', 'color', '');  clickHandlerSlider(item, '.nav li.active', 'border-color', '');   } } >

                                                                </a>
                                                            </li>
                                                            ))}
                                                        </ul>
                                                    </div>

                                                </li>
                                                <li className="ws-6 ps">
                                                    <div className="box">
                                                        <div className="ws-12 text-center ps text-bold">
                                                            Background
                                                        </div>

                                                        <ul className="ws-12 pm">
                                                            {CommonUtilities.myArray?.map(item => (
                                                            <li className=" pull-left width-auto ps">
                                                                <a className="pLarge btn ws-12 m0 roundedButtons" style={{background: item }}  onClick={ () => clickHandlerSlider(item, '.nav li.active', 'background', '') } >

                                                                </a>
                                                            </li>
                                                            ))}
                                                        </ul>

                                                    </div>
                                                    
                                                </li>

                                            
                                        </ul>

                                    </div>

                                        <ul className="nav nav-tabs ">
                                            <li className="nav-horizontal">Believe</li>
                                            <li className="active nav-horizontal">Distrokid</li>
                                            <li className="nav-horizontal">Ditto</li>
                                            <li className="nav-horizontal">iTunes</li>
                                        </ul>
                                        <div>
                                            <button className="btn-purple pLarge m0 ws-2">Search</button>
                                            <input type="text" className="pLarge m0 heightReset" placeholder="Type to search" value=""></input>
                                        </div>

                         

                                        <div className="box mtl">
                                            <div className="box-header">

                                                <div className="hover-box pull-left">
                                                
                                                    <button className="ps btn"><i className="icon-droplet"></i></button>
            
                                                    <ul className="hover-block landing ws-3 pull-left m0 hide">
                                                        <li className="ws-6 ps">
                                                                <div className="box">
                                                                    <div className="ws-12 text-center ps text-bold">
                                                                        Text
                                                                    </div>
            
                                                                    <ul className="ws-12 pm">
                                                                        {CommonUtilities.myArray?.map(item => (
                                                                        <li className=" pull-left width-auto ps">
                                                                            <a className="pLarge btn ws-12 m0 roundedButtons" style={{background: item }} onClick={ () => { clickHandlerSlider(item, '.box-header h2, .box-header', 'color', '');  clickHandlerSlider(item, '.nav li.active', 'border-color', '');   } } >
            
                                                                            </a>
                                                                        </li>
                                                                        ))}
                                                                    </ul>
                                                                </div>
            
                                                            </li>
                                                            <li className="ws-6 ps">
                                                                <div className="box">
                                                                    <div className="ws-12 text-center ps text-bold">
                                                                        Background
                                                                    </div>
            
                                                                    <ul className="ws-12 pm">
                                                                        {CommonUtilities.myArray?.map(item => (
                                                                        <li className=" pull-left width-auto ps">
                                                                            <a className="pLarge btn ws-12 m0 roundedButtons" style={{background: item }}  onClick={ () => clickHandlerSlider(item, '.box-header', 'background', '') } >
            
                                                                            </a>
                                                                        </li>
                                                                        ))}
                                                                    </ul>
            
                                                                </div>
                                                                
                                                            </li>
            
                                                        
                                                    </ul>
            
                                                </div>

                                                <h2 className="pull-left ">Sub Headers</h2>

                                                </div>
                                                <div className="box-content">
                                                    <table className="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th>Time</th>
                                                                <th>Name</th>
                                                                <th>Date Created</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>04:30:37 </td>
                                                                <td> Step1_Log</td>
                                                                <td>Apr 01, 2024 </td>
                                                            </tr>
                                                            <tr>
                                                                <td>04:30:37 </td>
                                                                <td> Finished Matching Formats </td><td>Apr 01, 2024 </td></tr><tr><td>04:30:36 </td><td> Start Matching Formats </td><td>Apr 01, 2024 </td></tr><tr><td>03:47:00 </td><td> Step1_Log</td><td>Apr 01, 2024 </td></tr><tr><td>03:47:00 </td><td> Catalogue matching Completed</td><td>Apr 01, 2024 </td></tr><tr><td>03:46:58 </td><td> Catalogue matching Started</td><td>Apr 01, 2024 </td></tr><tr><td>03:45:28 </td><td> Step1_Log</td><td>Apr 01, 2024 </td></tr><tr><td>03:45:28 </td><td> Finished Matching Formats </td><td>Apr 01, 2024 </td></tr><tr><td>03:45:27 </td><td> Start Matching Formats </td><td>Apr 01, 2024 </td></tr><tr><td>03:44:59 </td><td> Step1_Log</td><td>Apr 01, 2024 </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>

                                                    <div className="alert mtl"> Warning messages </div>

                                                </div>
                                                <div className="pll ml"> 
                                                <div className="pull-left pagination">
                                                    <ul><li>First</li><li  className="ws-1">Prev</li><li className="active"> 1 </li><li className=""> 2 </li><li className=""> 3 </li><li className=""> ...</li><li  className="ws-1">Next</li><li  className="ws-1">Last </li><select className="ml"><option value="10">10</option><option value="20">20</option><option value="50">50</option><option value="100">100</option></select></ul>
                                                </div>
                                            </div>

                                            <div className="box-footer">
                                                <button className="btn-success">

                                                    <div className="hover-box pull-left">
                                                    
                                                        <button className="btn p0 m0"><i className="icon-droplet"></i></button>
                
                                                        <ul className="hover-block landing ws-3 pull-left m0 hide rightReset">
                                                            <li className="ws-6 ps">
                                                                <div className="box">
                                                                    <div className="ws-12 text-center ps text-bold">
                                                                        Text
                                                                    </div>
            
                                                                    <ul className="ws-12 pm">
                                                                        {CommonUtilities.myArray?.map(item => (
                                                                        <li className=" pull-left width-auto ps">
                                                                            <a className="pLarge btn ws-12 m0 roundedButtons" style={{background: item }} onClick={ () => { clickHandlerSlider(item, '.btn-success', 'color', ''); clickHandlerSlider(item, '.btn-success', 'border-color', ''); } } >
            
                                                                            </a>
                                                                        </li>
                                                                        ))}
                                                                    </ul>
                                                                </div>
                                                            </li>
                                                            <li className="ws-6 ps">
                                                                <div className="box">
                                                                    <div className="ws-12 text-center ps text-bold">
                                                                        Background
                                                                    </div>
            
                                                                    <ul className="ws-12 pm">
                                                                        {CommonUtilities.myArray?.map(item => (
                                                                        <li className=" pull-left width-auto ps">
                                                                            <a className="pLarge btn ws-12 m0 roundedButtons" style={{background: item }}  onClick={ () => clickHandlerSlider(item, '.btn-success', 'background', '') } >
            
                                                                            </a>
                                                                        </li>
                                                                        ))}
                                                                    </ul>
                                                                </div>
                                                            </li>
                                                        </ul>
                
                                                    </div>
                                                    
                                                    Save / Search 
                                                </button>



                                                <button className="btn-info"> 

                                                    <div className="hover-box pull-left">
                                                        
                                                        <button className="btn p0 m0"><i className="icon-droplet"></i></button>
                
                                                        <ul className="hover-block landing ws-3 pull-left m0 hide rightReset">
                                                            <li className="ws-6 ps">
                                                                    <div className="box">
                                                                        <div className="ws-12 text-center ps text-bold">
                                                                            Text
                                                                        </div>
                
                                                                        <ul className="ws-12 pm">
                                                                            {CommonUtilities.myArray?.map(item => (
                                                                            <li className=" pull-left width-auto ps">
                                                                                <a className="pLarge btn ws-12 m0 roundedButtons" style={{background: item }} onClick={ () => { clickHandlerSlider(item, '.btn-info', 'color', ''); clickHandlerSlider(item, '.btn-info', 'border-color', ''); } } >
                
                                                                                </a>
                                                                            </li>
                                                                            ))}
                                                                        </ul>
                                                                    </div>
                
                                                                </li>
                                                                <li className="ws-6 ps">
                                                                    <div className="box">
                                                                        <div className="ws-12 text-center ps text-bold">
                                                                            Background
                                                                        </div>
                
                                                                        <ul className="ws-12 pm">
                                                                            {CommonUtilities.myArray?.map(item => (
                                                                            <li className=" pull-left width-auto ps">
                                                                                <a className="pLarge btn ws-12 m0 roundedButtons" style={{background: item }}  onClick={ () => clickHandlerSlider(item, '.btn-info', 'background', '') } >
                
                                                                                </a>
                                                                            </li>
                                                                            ))}
                                                                        </ul>
                
                                                                    </div>
                                                                    
                                                                </li>
                
                                                            
                                                        </ul>
                
                                                    </div>
                                                
                                                    Refresh / Next / Previous / Active 
                                                </button>



                                                <button className="btn-warning"> 
                                                    
                                                    <div className="hover-box pull-left">
                                                        
                                                        <button className="btn p0 m0"><i className="icon-droplet"></i></button>
                
                                                        <ul className="hover-block landing ws-3 pull-left m0 hide">
                                                            <li className="ws-6 ps">
                                                                    <div className="box">
                                                                        <div className="ws-12 text-center ps text-bold">
                                                                            Text
                                                                        </div>
                
                                                                        <ul className="ws-12 pm">
                                                                            {CommonUtilities.myArray?.map(item => (
                                                                            <li className=" pull-left width-auto ps">
                                                                                <a className="pLarge btn ws-12 m0 roundedButtons" style={{background: item }} onClick={ () => { clickHandlerSlider(item, '.btn-warning', 'color', ''); clickHandlerSlider(item, '.btn-warning', 'border-color', ''); } } >
                
                                                                                </a>
                                                                            </li>
                                                                            ))}
                                                                        </ul>
                                                                    </div>
                
                                                                </li>
                                                                <li className="ws-6 ps">
                                                                    <div className="box">
                                                                        <div className="ws-12 text-center ps text-bold">
                                                                            Background
                                                                        </div>
                
                                                                        <ul className="ws-12 pm">
                                                                            {CommonUtilities.myArray?.map(item => (
                                                                            <li className=" pull-left width-auto ps">
                                                                                <a className="pLarge btn ws-12 m0 roundedButtons" style={{background: item }}  onClick={ () => clickHandlerSlider(item, '.btn-warning', 'background', '') } >
                
                                                                                </a>
                                                                            </li>
                                                                            ))}
                                                                        </ul>
                
                                                                    </div>
                                                                    
                                                                </li>
                
                                                            
                                                        </ul>
                
                                                    </div>

                                                    Calculate 

                                                </button>



                                                <button className="btn-danger"> 


                                                <div className="hover-box pull-left">
                                                        
                                                        <button className="btn p0 m0"><i className="icon-droplet"></i></button>
                
                                                        <ul className="hover-block landing ws-3 pull-left m0 hide">
                                                            <li className="ws-6 ps">
                                                                    <div className="box">
                                                                        <div className="ws-12 text-center ps text-bold">
                                                                            Text
                                                                        </div>
                
                                                                        <ul className="ws-12 pm">
                                                                            {CommonUtilities.myArray?.map(item => (
                                                                            <li className=" pull-left width-auto ps">
                                                                                <a className="pLarge btn ws-12 m0 roundedButtons" style={{background: item }} onClick={ () => { clickHandlerSlider(item, '.btn-danger', 'color', ''); clickHandlerSlider(item, '.btn-danger', 'border-color', ''); } } >
                
                                                                                </a>
                                                                            </li>
                                                                            ))}
                                                                        </ul>
                                                                    </div>
                
                                                                </li>
                                                                <li className="ws-6 ps">
                                                                    <div className="box">
                                                                        <div className="ws-12 text-center ps text-bold">
                                                                            Background
                                                                        </div>
                
                                                                        <ul className="ws-12 pm">
                                                                            {CommonUtilities.myArray?.map(item => (
                                                                            <li className=" pull-left width-auto ps">
                                                                                <a className="pLarge btn ws-12 m0 roundedButtons" style={{background: item }}  onClick={ () => clickHandlerSlider(item, '.btn-danger', 'background', '') } >
                
                                                                                </a>
                                                                            </li>
                                                                            ))}
                                                                        </ul>
                
                                                                    </div>
                                                                    
                                                                </li>
                
                                                            
                                                        </ul>
                
                                                    </div>
                                                
                                                Delete / Remove / Close </button>



                                            </div>

                                        </div>

                                    </div>

                                </div>


                            </div>
                        </div>

                    </div>

                    <div className="box-footer">
                        <button className="btn-success" onClick={ () => saveTheme() }>
                              Apply Theme                                              
                        </button>
                    </div>
                </div>

            </Modal>
        </div>
    );

}