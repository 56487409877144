import React, { useEffect, useState } from "react";
import clientProfileServices from "../clientProfileServices";
import TextEditor from "../../../Common/textEditor";

export default function Email_Template (param:any) {

    const [emailTempList, initEmailTemplist] = useState<any>([]);
     const[selection, setSelection] = useState("");

    useEffect(() => {
        clientProfileServices.emailTemp().then((res) => { initEmailTemplist(res[0])})
    }, []);

    const handleSelectionChange = (e : any) => {
        setSelection(e.target.value)
    };

    const fieldHandle =(e:any) =>  {
        var field_name = e.target.name;
        var field_value = e.target.value;
        initEmailTemplist({ ...emailTempList, ...{ [field_name]: field_value } });
    }

    const saveAllDefaults = () => {
        clientProfileServices.saveEmailUserTemplate(emailTempList);
        console.log(emailTempList)
    }

    const onchangeEditorSignature = (e:any) => {
        console.log("onchangeEditorSignature")
        console.log(e)
        emailTempList.signature = e
    }

    const onchangeEditorMessage = (e:any) => {
        console.log("onchangeEditorMessage")
        console.log(e)
        emailTempList.message = e
    }

    const onchangeEditorAttachment = (e:any) => {
        console.log("onchangeEditorAttachment")
        console.log(e)
        emailTempList.attachment = e
    }  
    
    return (
        
        <div className="box box-add-region">
            <div className="box-header well">
                <h2><i className="icon-globe1 pt pr"></i> Email Template </h2>
            </div>

            <div className="box-content"> 
                <div className="form-format ws-12 mtl">
                    <label className="control-label text-bold ws-12">Type</label>
                    <div className="label-entries">
                        <select onChange={handleSelectionChange} className="ws-6">
                                <option value={emailTempList.typeId}>{emailTempList.type} </option>                                            
                        </select>
                    </div>
                </div>

                <div className="form-format ws-12 mtl">
                    <label className="control-label text-bold ws-12">Subject</label>
                    <div className="label-entries w-45">
                        <input type="text" name="subject" value={emailTempList.subject} onChange={fieldHandle} placeholder="Enter Subject"/>
                    </div>
                </div>
                <div className="form-format ws-12 mtl">
                    <label className="control-label text-bold ws-12">Message</label>
                    <div className="label-entries w-45">

                        <div className="box pm ">
                            <TextEditor onchangeEditor ={onchangeEditorMessage} textvalue={emailTempList.message}/>
                        </div> 
                        <textarea className="hide" placeholder="Enter a Message" name="message" onChange={fieldHandle} value={emailTempList.message}></textarea>
                    </div>
                </div>

                <div className="form-format ws-12 mtl">
                    <label className="control-label text-bold ws-12">Attachment Limit Exceeded</label>
                    <div className="label-entries w-45">

                        <div className="box pm ">
                            <TextEditor onchangeEditor ={onchangeEditorAttachment} textvalue={emailTempList.attachment}/>
                        </div>   
                        <textarea className="hide" placeholder="Enter Attachment" name="message" onChange={fieldHandle} value={emailTempList.attachment}></textarea>
                    </div>
                </div>

                <div className="form-format ws-12 mtl">
                    <label className="control-label text-bold ws-12">Signature</label>
                    <div className="label-entries w-45">

                        <div className="box pm ">
                            <TextEditor onchangeEditor ={onchangeEditorSignature} textvalue={emailTempList.signature}/>
                        </div>                
                        <textarea className="hide" placeholder="Enter Signature" name="message" onChange={fieldHandle} value={emailTempList.signature}></textarea>
                    </div>
                </div>                
                
            </div>

            <div className="box-footer pull-right">
                <button className="btn btn-success btn-sm" onClick={saveAllDefaults}> Save </button>
                <button className="btn btn-pink btn-sm hide"><i className="icon-reload"></i> Reset</button>
            </div>
        </div>       

    )
}